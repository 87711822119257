import { IMaintenance } from "../entities/maintenance";
import { get, post } from "./helper";
import { IResponse } from "./response";

const GET_MAINTENANCE_URL = "/maintenance-api/get_maintenance_admin.php";
const POST_MAINTENANCE_URL = "/maintenance-api/post_maintenance.php";

// GET
export interface IGetMaintenanceRequest {}

export interface IGetMaintenanceResponse extends IResponse {
    maintenance: IMaintenance;
}

export async function getMaintenance(data: IGetMaintenanceRequest, callback: ((response: IGetMaintenanceResponse) => void)) {
    const getCallBack = (response: any) => {
        if (response.ok) {
            callback({ 
                ok: response?.ok, 
                message: response?.message,
                maintenance: response?.data?.maintenance,
            });
        } else {
            callback(response);
        }
    };

    get(GET_MAINTENANCE_URL, data, getCallBack);
}

// POST NEW
export interface IPostMaintenanceRequest {
    maintenance: boolean;
    maintenance_until: string;
}

export interface IPostMaintenanceResponse extends IResponse {}

export async function postMaintenance(data: IPostMaintenanceRequest, callback: ((response: IPostMaintenanceResponse) => void)) {
    const postCallBack = (response: any) => {
        callback(response);
    };

    post(POST_MAINTENANCE_URL, data, postCallBack);
}