import { Link } from "react-router-dom";
import styled from "styled-components";
import { DeviceSize } from "../../utils/deviceSize";

// PAGE
export const Section = styled.section`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const Content = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* background-color: aliceblue; */

    @media ${`(min-width: ${DeviceSize.extraLarge}px)`} {
        width: ${props => props.theme.general.page.xxlWidth};
    }

    @media ${`(min-width: ${DeviceSize.large}px) and (max-width: ${DeviceSize.extraLarge}px)`} {
        width: ${props => props.theme.general.page.xlWidth};
    }

    @media ${`(min-width: ${DeviceSize.medium}px) and (max-width: ${DeviceSize.large}px)`} {
        width: ${props => props.theme.general.page.lWidth};
    }

    @media ${`(min-width: ${DeviceSize.small}px) and (max-width: ${DeviceSize.medium}px)`} {
        width: ${props => props.theme.general.page.mWidth};
    }
`;

export const ContentPadding = styled.div`
    padding: ${props => props.theme.general.page.padding};
`;

export const ResponsiveRow = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: start;

    & >:nth-child(1n+0) { 
        margin-left: 10px;

        @media ${`(max-width: ${DeviceSize.medium}px)`} {
            margin-left: 0 !important; 
        }
    }

    & >:first-child { 
        margin-left: 0 !important; 
    }

    @media ${`(max-width: ${DeviceSize.medium}px)`} {
        flex-direction: column;
    }
`;

// BUTTONS
interface ILinkButton {
    backgroundColor?: string,
    color?: string,
}

export const Button = styled(Link)<ILinkButton>`
    display: inline-block;
    padding: ${props => props.theme.general.button.padding};
    font-weight: ${props => props.theme.general.button.fontWeight};
    background-color: ${(p: ILinkButton) => p.backgroundColor || (props => props.theme.general.button.backgroundColor)};
    color: ${(p: ILinkButton) => p.color || (props => props.theme.general.button.color)};
    border-radius: ${props => props.theme.general.button.borderRadius};
    -webkit-transition: ${props => props.theme.general.button.transition};
    transition: ${props => props.theme.general.button.transition};
    text-align: center;

    &:hover {
        opacity: ${props => props.theme.general.button.hoverOpacity};
    }
`;

interface IBasicButton {
    backgroundColor?: string,
    color?: string,
}

export const BasicButton = styled.button`
    width: 100%;
    display: inline-block;
    border: none;
    padding: ${props => props.theme.general.button.padding};
    font-weight: ${props => props.theme.general.button.fontWeight};
    background-color: ${(p: IBasicButton) => p.backgroundColor || (props => props.theme.general.button.backgroundColor)};
    color: ${(p: IBasicButton) => p.color || (props => props.theme.general.button.color)};
    border-radius: ${props => props.theme.general.button.borderRadius};
    -webkit-transition: ${props => props.theme.general.button.transition};
    transition: ${props => props.theme.general.button.transition};

    &:hover {
        opacity: ${props => props.theme.general.button.hoverOpacity};
    }
`;

// FORM
export const FormTitle = styled.div`
    margin-bottom: ${props => props.theme.general.form.title.marginBottom};
    font-size: ${props => props.theme.general.form.title.fontSize};
    font-weight: ${props => props.theme.general.form.title.fontWeight};
    text-transform: ${props => props.theme.general.form.title.textTransform};
`;

interface IForm {
    maxWidth?: string,
}

export const Form = styled.form`
    width: 100%;
    max-width: ${(p: IForm) => p.maxWidth || "100%"};
    display: flex; 
    flex-direction: column;
    align-items: center;
`;
