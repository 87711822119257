import { apiUrl } from "../constant";
import { IAbout } from "../entities/about";
import { IMain } from "../entities/main";
import { IPicture } from "../entities/picture";
import { IService } from "../entities/service"
import { resizeImage } from "../utils/image";
import { get, post, postForm } from "./helper"
import { IResponse } from "./response"

export const IMAGES_PAGES_URL = apiUrl + "/pages-api/images/";

const GET_MAIN_URL = "/pages-api/get_main_admin.php";
const POST_MAIN_URL = "/pages-api/post_main.php";

const GET_ABOUT_URL = "/pages-api/get_about_admin.php";
const POST_ABOUT_URL = "/pages-api/post_about.php";

export const ICONS_SERVICES_URL = apiUrl + "/pages-api/images/services/icons/";
export const IMAGES_SERVICES_URL = apiUrl + "/pages-api/images/services/";
const GET_SERVICES_URL = "/pages-api/get_services_admin.php";
const POST_SERVICE_URL = "/pages-api/post_service.php";
const DELETE_SERVICE_URL = "/pages-api/delete_service.php";

export const IMAGES_PICTURES_URL = apiUrl + "/pages-api/images/pictures/";
const GET_PICTURES_URL = "/pages-api/get_pictures_admin.php";
const POST_PICTURE_URL = "/pages-api/post_picture.php";
const DELETE_PICTURE_URL = "/pages-api/delete_picture.php";

/////////////////////////////////////////////
// MAIN
/////////////////////////////////////////////
// GET
export interface IGetMainRequest {}

export interface IGetMainResponse extends IResponse {
    main: IMain;
}

export async function getMain(data: IGetMainRequest, callback: ((response: IGetMainResponse) => void)) {
    const getCallBack = (response: any) => {
        if (response.ok) {
            callback({ 
                ok: response?.ok, 
                message: response?.message,
                main: response?.data?.main,
            });
        } else {
            callback(response);
        }
    };

    get(GET_MAIN_URL, data, getCallBack);
}

// POST NEW
export interface IPostMainRequest {
    title: string;
    subtitle: string;
    action_text: string;
}

export interface IPostMainResponse extends IResponse {}

export async function postMain(data: IPostMainRequest, callback: ((response: IPostMainResponse) => void)) {
    const postCallBack = (response: any) => {
        callback(response);
    };

    post(POST_MAIN_URL, data, postCallBack);
}

/////////////////////////////////////////////
// ABOUT
/////////////////////////////////////////////
// GET
export interface IGetAboutRequest {}

export interface IGetAboutResponse extends IResponse {
    about: IAbout;
}

export async function getAbout(data: IGetAboutRequest, callback: ((response: IGetAboutResponse) => void)) {
    const getCallBack = (response: any) => {
        if (response.ok) {
            callback({ 
                ok: response?.ok, 
                message: response?.message,
                about: response?.data?.about,
            });
        } else {
            callback(response);
        }
    };

    get(GET_ABOUT_URL, data, getCallBack);
}

// POST NEW
export interface IPostAboutRequest {
    title: string;
    description: string;
    up_image: boolean;
    image: File;
    years_xp: string;
    projects: string;
    customer_satisfaction: string;
}

export interface IPostAboutResponse extends IResponse {}

export async function postAbout(data: IPostAboutRequest, callback: ((response: IPostAboutResponse) => void)) {
    const postCallBack = (response: any) => {
        callback(response);
    };

    if(data.image) {
        data.image = await resizeImage(data.image, "JPEG", 384, 384) as File;
    }

    data.description = data.description.replace(/<p><br><\/p>/g, "");

    postForm(POST_ABOUT_URL, data, postCallBack);
}

/////////////////////////////////////////////
// SERVICES
/////////////////////////////////////////////
// GET ALL
export interface IGetAllServiceRequest {}

export interface IGetAllServiceResponse extends IResponse {
    services: IService[];
}

export async function getAllServices(data: IGetAllServiceRequest, callback: ((response: IGetAllServiceResponse) => void)) {
    const getCallBack = (response: any) => {
        if (response.ok) {
            callback({ 
                ok: response?.ok, 
                message: response?.message,
                services: response?.data?.services,
            });
        } else {
            callback(response);
        }
    };

    get(GET_SERVICES_URL, data, getCallBack);
}

// POST NEW
export interface IPostServiceRequest {
    id: number;
    up_icon: boolean;
    icon: File;
    title: string;
    summary: string;
    description: string;
    up_image: boolean;
    image: File;
}

export interface IPostServiceResponse extends IResponse {}

export async function postService(data: IPostServiceRequest, callback: ((response: IPostServiceResponse) => void)) {
    const postCallBack = (response: any) => {
        callback(response);
    };

    if(data.image) {
        data.image = await resizeImage(data.image, "JPEG", 512, 512) as File;
    }

    if(data.icon) {
        data.icon = await resizeImage(data.icon, "PNG", 64, 64) as File;
    }
    
    data.description = data.description.replace(/<p><br><\/p>/g, "");
    
    postForm(POST_SERVICE_URL, data, postCallBack);
}

// DELETE
export interface IDeleteServiceRequest {
    id: number;
}

export interface IDeleteServiceResponse extends IResponse {}

export async function deleteService(data: IDeleteServiceRequest, callback: ((response: IDeleteServiceResponse) => void)) {
    const postCallBack = (response: any) => {
        if (response.ok) {
            callback({ 
                ok: response?.ok, 
                message: response?.message,
            });
        } else {
            callback(response);
        }
    };

    post(DELETE_SERVICE_URL, data, postCallBack);
}

/////////////////////////////////////////////
// PICTURES
/////////////////////////////////////////////
// GET ALL
export interface IGetAllPicturesRequest {}

export interface IGetAllPicturesResponse extends IResponse {
    pictures: IPicture[];
}

export async function getAllPictures(data: IGetAllPicturesRequest, callback: ((response: IGetAllPicturesResponse) => void)) {
    const getCallBack = (response: any) => {
        if (response.ok) {
            callback({ 
                ok: response?.ok, 
                message: response?.message,
                pictures: response?.data?.pictures,
            });
        } else {
            callback(response);
        }
    };

    get(GET_PICTURES_URL, data, getCallBack);
}

// POST NEW
export interface IPostPictureRequest {
    id: number;
    description: string;
    up_image: boolean;
    image: File;
    image_mobile: File;
    thumb: File;
}

export interface IPostPictureResponse extends IResponse {}

export async function postPicture(data: IPostPictureRequest, callback: ((response: IPostPictureResponse) => void)) {
    const postCallBack = (response: any) => {
        callback(response);
    };

    if(data.image) {
        data.image = await resizeImage(data.image, "JPEG", 1024, 1024) as File;
        data.image_mobile = await resizeImage(data.image, "JPEG", 400, 400) as File;
        data.thumb = await resizeImage(data.image, "JPEG", 200, 200) as File;
    }
    
    postForm(POST_PICTURE_URL, data, postCallBack);
}

// DELETE
export interface IDeletePictureRequest {
    id: number;
}

export interface IDeletePictureResponse extends IResponse {}

export async function deletePicture(data: IDeletePictureRequest, callback: ((response: IDeletePictureResponse) => void)) {
    const postCallBack = (response: any) => {
        if (response.ok) {
            callback({ 
                ok: response?.ok, 
                message: response?.message,
            });
        } else {
            callback(response);
        }
    };

    post(DELETE_PICTURE_URL, data, postCallBack);
}