import { useTable } from "react-table";
import styled from "styled-components";
import { BiEdit } from "react-icons/bi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useEffect, useMemo, useState } from "react";
import { BasicButton } from "../common";

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const Marginer = styled.div`
  height: 20px;;
`;

const Table = styled.table`
    border-spacing: 0;
    border: 1px solid #c7c7c7;
    border-collapse: collapse;

    & th {
      background-color: #f2f2f2;
      text-align: start;
      padding: 10px;
      border: 1px solid #c7c7c7;
      border-collapse: collapse;
    }

    & tr:nth-child(even) {
      background-color: #f2f2f2;
    }

    & td {
      padding: 10px;
      border: 1px solid #c7c7c7;
      border-collapse: collapse;
    }
`;

const Actions = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    & >:nth-child(1n+0) { 
        margin-left: 10px;
        cursor: pointer;
    }

    & >:first-child { 
        margin-left: 0 !important; 
    }
`;

export interface ICrudTableColumn {
    Header: string;
    accessor: string;
    Cell?: (props: any) => JSX.Element;
}


interface ICrudTable {
    // columns: Column<any>[];
    columns: ICrudTableColumn[];
    data: any[];
    onAdd: () => void;
    onEdit: (index: number) => void;
    onDelete: (index: number) => void;
}

const CrudTable = (({ columns, data, onAdd, onEdit, onDelete }: ICrudTable) => {
    const [tableCols, setTableCols] = useState<ICrudTableColumn[]>([]);

    useEffect(() => {
        const cols = columns.slice();
        cols.push({
            Header: "Actions",
            accessor: "actions",
            Cell: (props: any) => {
                const index = props.row.id;
                return (
                <Actions>
                    <span onClick={() => onEdit(index)}>
                        <BiEdit size={20} />
                    </span>
    
                    <span onClick={() => onDelete(index)}>
                        <RiDeleteBin6Line size={20} />
                    </span>
                </Actions>
                );
            },
        });
        setTableCols(cols);
    }, [columns, setTableCols, onEdit, onDelete]);

   const tableColumns = useMemo(
        () => 
        tableCols.map((el, i) => {
            if(el.Cell) {
                return {
                    Header: el.Header,
                    accessor: el.accessor,
                    Cell: el.Cell,
                };
            }
            return {
                Header: el.Header,
                accessor: el.accessor,
            };
        }),
        [tableCols]
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = useTable({
        columns: tableColumns,
        data
    });

    return (
      <Container>
        <BasicButton onClick={onAdd}>Ajouter</BasicButton>
        <Marginer/>
        <Table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Container>
    );
});

export default CrudTable;