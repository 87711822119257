import { AxiosError } from "axios";
import axios from "./axios";

function encodeQueryData(data: any) {
    const ret = [];
    for (let d in data)
      ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
    return ret.join('&');
 }

export async function get(url: string, data: any, callback: ((response: any) => void)) {
    let query = encodeQueryData(data);
    axios.get(url.concat("?").concat(query),
        {
            headers: {'Content-Type': 'application/json;charset=UTF-8'},
            withCredentials: true
        }
    )
    .then((response: any) => {
        callback({
            ok: true,
            message: "",
            data: response.data,
        });
    })
    .catch((err: unknown | AxiosError) => {
        if (err instanceof AxiosError)  {
            if(!err?.response) {
                callback({ 
                    ok: false, 
                    message: "Le serveur ne répond pas.", 
                });
            } else if (err.response?.status === 406) {
                callback({ 
                    ok: false, 
                    message: err.response?.data?.message ?? "Format de requête incorrect.", 
                });
            } else if (err.response?.status === 401) {
                callback({ 
                    ok: false,
                    message: "Non authorisé", 
                    unauthorized: true,
                });
            } else {
                callback({ 
                    ok: false, 
                    message: "L'application a rencontré une erreur. Merci de contacter votre administrateur.", 
                });
            }
        } else {
            callback({ 
                ok: false, 
                message: "L'application a rencontré une erreur. Merci de contacter votre administrateur.", 
            });
        }
    });
}

export async function post(url: string, data: any, callback: ((response: any) => void)) {
    axios.post(url,
        data,
        {
            headers: {'Content-Type': 'application/json;charset=UTF-8'},
            withCredentials: true
        }
    )
    .then((response: any) => {
        callback({
            ok: true,
            message: "",
            data: response.data,
        });
    })
    .catch((err: unknown | AxiosError) => {
        if (err instanceof AxiosError)  {
            if(!err?.response) {
                callback({ 
                    ok: false, 
                    message: "Le serveur ne répond pas.", 
                });
            } else if (err.response?.status === 406) {
                callback({ 
                    ok: false, 
                    message: err.response?.data?.message ?? "Format de requête incorrect.", 
                });
            } else if (err.response?.status === 401) {
                callback({ 
                    ok: false, 
                    message: "Non authorisé",
                    unauthorized: true, 
                });
            } else {
                callback({ 
                    ok: false, 
                    message: "L'application a rencontré une erreur. Merci de contacter votre administrateur.", 
                });
            }
        } else {
            callback({ 
                ok: false, 
                message: "L'application a rencontré une erreur. Merci de contacter votre administrateur.", 
            });
        }
    });
}

export async function postForm(url: string, data: any, callback: ((response: any) => void)) {
    const formData =  new FormData();
    let array = Object.getOwnPropertyNames(data);
    array.forEach((property: string) => {
        type ObjectKey = keyof typeof data;
        const myVar = data[property as ObjectKey];
        formData.append(property, myVar);
    });

    axios.post(url,
        formData,
        {
            headers: {'Content-Type': 'application/json;charset=UTF-8'},
            withCredentials: true
        }
    )
    .then((response: any) => {
        callback({
            ok: true,
            message: "",
            data: response.data,
        });
    })
    .catch((err: unknown | AxiosError) => {
        if (err instanceof AxiosError)  {
            if(!err?.response) {
                callback({ 
                    ok: false, 
                    message: "Le serveur ne répond pas.", 
                });
            } else if (err.response?.status === 406) {
                callback({ 
                    ok: false, 
                    message: err.response?.data?.message ?? "Format de requête incorrect.", 
                });
            } else if (err.response?.status === 401) {
                callback({ 
                    ok: false, 
                    message: "Non authorisé", 
                    unauthorized: true,
                });
            } else {
                callback({ 
                    ok: false, 
                    message: "L'application a rencontré une erreur. Merci de contacter votre administrateur.", 
                });
            }
        } else {
            callback({ 
                ok: false, 
                message: "L'application a rencontré une erreur. Merci de contacter votre administrateur.", 
            });
        }
    });
}