import { toast } from "react-toastify";
import { changePassword, IChangePasswordRequest, IChangePasswordResponse } from "../../api/changePasswordServices";
import { Content, ContentPadding, Section } from "../../components/common";
import { ChangePasswordForm } from "../../components/forms/changePasswordForm";

const ChangePasswordPage = () => {
    const onSubmit = async (request: IChangePasswordRequest) => {
        const callback = (response: IChangePasswordResponse) => {
            if (response.ok) {
                toast.success("Votre mot de passe a été modifié.");
            } else {
                toast.error(response.message);
            }
        };
        
        await changePassword(request, callback);
    };

    return (
        <Section>
            <Content>
                <ContentPadding>
                    <ChangePasswordForm onSubmit={onSubmit} />
                </ContentPadding>
            </Content>
        </Section>
    );
};

export default ChangePasswordPage;