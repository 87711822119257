import { IIdentity } from "../entities/identity";
import { IOpeningTimes } from "../entities/openingTimes";
import { ISocial } from "../entities/social";
import { get, post } from "./helper"
import { IResponse } from "./response"

const GET_IDENTITY_URL = "/identity-api/get_identity.php";
const POST_IDENTITY_URL = "/identity-api/post_identity.php";

const GET_SOCIAL_URL = "/identity-api/get_social.php";
const POST_SOCIAL_URL = "/identity-api/post_social.php";

const GET_OPENINGTIMES_URL = "/identity-api/get_opening_times_admin.php";
const POST_OPENINGTIMES_URL = "/identity-api/post_opening_times.php";


/////////////////////////////////////////////
// IDENTITY
/////////////////////////////////////////////
// GET IDENTITY
export interface IGetIdentityRequest {}

export interface IGetIdentityResponse extends IResponse {
    identity: IIdentity;
}

export async function getIdentity(data: IGetIdentityRequest, callback: ((response: IGetIdentityResponse) => void)) {
    const getCallBack = (response: any) => {
        if (response.ok) {
            callback({ 
                ok: response?.ok, 
                message: response?.message,
                identity: response?.data?.identity,
            });
        } else {
            callback(response);
        }
    };

    get(GET_IDENTITY_URL, data, getCallBack);
}

// POST NEW IDENTITY
export interface IPostIdentityRequest {
    name: string
    address_1: string
    address_2?: string
    zip_code: string
    city: string
    phone_number: string
    email: string
    website_url: string
}

export interface IPostIdentityResponse extends IResponse {}

export async function postIdentity(data: IPostIdentityRequest, callback: ((response: IPostIdentityResponse) => void)) {
    const postCallBack = (response: any) => {
        callback(response);
    };

    post(POST_IDENTITY_URL, data, postCallBack);
}

/////////////////////////////////////////////
// SOCIAL
/////////////////////////////////////////////
// GET SOCIAL
export interface IGetSocialRequest {}

export interface IGetSocialResponse extends IResponse {
    social: ISocial;
}

export async function getSocial(data: IGetSocialRequest, callback: ((response: IGetSocialResponse) => void)) {
    const getCallBack = (response: any) => {
        if (response.ok) {
            callback({ 
                ok: response?.ok, 
                message: response?.message,
                social: response?.data?.social,
            });
        } else {
            callback(response);
        }
    };

    get(GET_SOCIAL_URL, data, getCallBack);
}

// POST NEW SOCIAL
export interface IPostSocialRequest {
    linkedin: string;
    facebook: string;
    twitter: string;
    skype: string;
    instagram: string;
    youtube: string;
    tiktok: string;
    pinterest: string;
    discord: string;
    twitch: string;
}

export interface IPostSocialResponse extends IResponse {}

export async function postSocial(data: IPostSocialRequest, callback: ((response: IPostSocialResponse) => void)) {
    const postCallBack = (response: any) => {
        callback(response);
    };

    post(POST_SOCIAL_URL, data, postCallBack);
}

/////////////////////////////////////////////
// OPENING TIMES
/////////////////////////////////////////////
// GET OPENING TIMES
export interface IGetOpeningTimesRequest {}

export interface IGetOpeningTimesResponse extends IResponse {
    days: IOpeningTimes[];
}

export async function getOpeningTimes(data: IGetOpeningTimesRequest, callback: ((response: IGetOpeningTimesResponse) => void)) {
    const getCallBack = (response: any) => {
        if (response.ok) {
            callback({ 
                ok: response?.ok, 
                message: response?.message,
                days: response?.data?.days,
            });
        } else {
            callback(response);
        }
    };

    get(GET_OPENINGTIMES_URL, data, getCallBack);
}

// POST NEW OPENING TIMES
export interface IPostOpeningTimesRequest {
    monday_times: string;
    tuesday_times: string;
    wednesday_times: string;
    thursday_times: string;
    friday_times: string;
    saturday_times: string;
    sunday_times: string;
}

interface IRealPostOpeningTimesRequest {
    days: IDayTimes[];
}

interface IDayTimes {
    day: string;
    times: string;
}

export interface IPostOpeningTimesResponse extends IResponse {}

export async function postOpeningTimes(data: IPostOpeningTimesRequest, callback: ((response: IPostOpeningTimesResponse) => void)) {
    const monday: IDayTimes = {
        day: "monday",
        times: data.monday_times,
    }
    const tuesday: IDayTimes = {
        day: "tuesday",
        times: data.tuesday_times,
    }
    const wednesday: IDayTimes = {
        day: "wednesday",
        times: data.wednesday_times,
    }
    const thursday: IDayTimes = {
        day: "thursday",
        times: data.thursday_times,
    }
    const friday: IDayTimes = {
        day: "friday",
        times: data.friday_times,
    }
    const saturday: IDayTimes = {
        day: "saturday",
        times: data.saturday_times,
    }
    const sunday: IDayTimes = {
        day: "sunday",
        times: data.sunday_times,
    }

    const request: IRealPostOpeningTimesRequest = {
        days : [
            monday,
            tuesday,
            wednesday,
            thursday,
            friday,
            saturday,
            sunday,
        ],
    }

    const postCallBack = (response: any) => {
        callback(response);
    };

    post(POST_OPENINGTIMES_URL, request, postCallBack);
}