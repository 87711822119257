import { useFormik } from "formik";
import React, { useEffect, useRef } from "react";
import { FaUser } from "react-icons/fa";
import { RiLockPasswordFill } from "react-icons/ri"
import { BasicButton } from "../../common";
import * as yup from "yup";
import styled from "styled-components";
import Input from "../input";

const validationSchema = yup.object({
    username: yup.string()
        .required("Le nom d'utilisateur est obligatoire"),
    password: yup.string()
        .required("Le mot de passe est obligatoire"),
});

// Container
const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const FormContainer = styled.div`
    width: 100%;
    max-width: ${props => props.theme.login.maxWidth};
    border: ${props => props.theme.login.border};
`

const Form = styled.form`
    display: flex; 
    flex-direction: column;
    align-items: center;
    padding: ${props => props.theme.login.padding};
`;

// Title
const Title = styled.h1`
    margin: 0;
    padding: 0;
    margin-bottom: ${props => props.theme.login.title.marginBottom};
    text-transform: ${props => props.theme.login.title.textTransform};
`

interface ILoginFrom {
    onSubmit: (request: any) =>  void;
}

export const LoginForm = (({onSubmit}: ILoginFrom) => {
    const userRef = useRef() as React.MutableRefObject<HTMLInputElement>;

    useEffect(() => {
        userRef.current.focus();
    }, []);

    
    const formik = useFormik({
        initialValues: { username: "", password: "" },
        validateOnBlur: true,
        onSubmit,
        validationSchema: validationSchema,
    });

    return (
        <Container>
            <FormContainer>
            <Form onSubmit={formik.handleSubmit}>
                <Title>Webadmin</Title>
                <Input
                    icon= {<FaUser size={20} />}
                    id="username"
                    type="text"
                    name="username"
                    placeholder="Nom d'utilisateur"
                    ariaLabel="username"
                    value={formik.values.username} 
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    iRef={userRef}
                    autoComplete="off"
                    error={formik.touched.username && formik.errors.username ? formik.errors.username : ""} />
                <Input
                    icon= {<RiLockPasswordFill size={20} />}
                    id="password"
                    type="password"
                    name="password"
                    placeholder="Mot de passe"
                    ariaLabel="password"
                    value={formik.values.password} 
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.password && formik.errors.password ? formik.errors.password : ""} />
                <BasicButton type="submit">Se connecter</BasicButton>
            </Form>
            </FormContainer>
        </Container>
    );
});