import { useFormik } from "formik";
import { useEffect, useRef } from "react";
import { AiFillTool } from "react-icons/ai";
import styled from "styled-components";
import * as yup from "yup";
import { IMaintenance } from "../../../entities/maintenance";
import { BasicButton, Form, FormTitle } from "../../common";
import Input from "../input";

// Container
const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const validationSchema = yup.object({
    maintenance: yup.boolean(),
    maintenance_until: yup.string()
        .test("required", "La date de fin de maintenance est obligatoire",
            (value, ctx) => {
                if(ctx.parent.maintenance && (!value || value === "")) {
                    return false;
                }
                return true;
        })
        .matches(/^(0[1-9]|1\d|2[0-8]|29(?=\/\d\d\/(?!1[01345789]00|2[1235679]00)\d\d(?:[02468][048]|[13579][26]))|30(?!\/02)|31(?=\/0[13578]|\/1[02]))\/(0[1-9]|1[0-2])\/([12]\d{3}) ([01]\d|2[0-3]):([0-5]\d)$/, "Le format de date n'est pas correct"),
});

interface IMaintenanceForm {
    maintenance?: IMaintenance;
    onSubmit: (request: any) => void;
}

export const MaintenanceForm = (({ maintenance, onSubmit }: IMaintenanceForm) => {
    const ref = useRef() as React.MutableRefObject<HTMLInputElement>;

    useEffect(() => {
        ref.current.focus();
    }, []);
    
    const formik = useFormik({
        initialValues: { 
            maintenance: maintenance ? maintenance.maintenance === "1" : false , 
            maintenance_until: maintenance ? maintenance.maintenance_until : ""},
        enableReinitialize: true,
        validateOnBlur: true,
        onSubmit,
        validationSchema: validationSchema,
    });

    return (
        <Container>
            <FormTitle>Maintenance</FormTitle>
            <Form onSubmit={formik.handleSubmit}>
                <Input
                    icon= {<AiFillTool size={20} />}
                    id="maintenance"
                    type="checkbox"
                    name="maintenance"
                    label="En maintenance"
                    placeholder="mettre le site en maintenance"
                    ariaLabel="maintenance"
                    checked={formik.values.maintenance} 
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    iRef={ref}
                    error={formik.touched.maintenance && formik.errors.maintenance ? formik.errors.maintenance : ""} />
                
                <Input
                    indent
                    id="maintenance_until"
                    type="text"
                    name="maintenance_until"
                    label="Date de fin de maintenance"
                    placeholder="date dd/jj/yyyy hh:mm ex : 14/09/2020 15:00"
                    ariaLabel="maintenance until"
                    value={formik.values.maintenance_until} 
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.maintenance_until && formik.errors.maintenance_until ? formik.errors.maintenance_until : ""} />
                <BasicButton type="submit">Enregistrer</BasicButton>
            </Form>
        </Container>
    );
});
