import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ILoginRequest, ILoginResponse, login } from "../api/authServices";
import { Content, ContentPadding, Section } from "../components/common";
import { LoginForm } from "../components/forms/loginForm";
import useAuth from "../hooks/useAuth";

interface LocationState {
    from: {
      pathname: string;
    };
}

const LoginPage = () => {
    const { setAuth } = useAuth();

    const navigate = useNavigate();
    const location = useLocation();
    const from = (location.state as LocationState)?.from?.pathname || "/";

    const onSubmit = async (request: ILoginRequest) => {
        const callback = (response: ILoginResponse) => {
            if (response.ok) {
                setAuth(true);
                sessionStorage.setItem("isLoggedIn", "1");
                if(response.changePassword) {
                    toast.warning("Pour la sécurité du site, il est important de modifier le mot de passe par défaut.");
                    navigate("/changepassword", { replace: true })
                } else {
                    navigate(from, { replace: true });
                }
            } else {
                toast.error(response.message);
            }
        };

        await login(request, callback);
    };

    return (
        <Section>
            <Content>
                <ContentPadding>
                    <LoginForm onSubmit={onSubmit} />
                </ContentPadding>
            </Content>
        </Section>
    );
};

export default LoginPage;