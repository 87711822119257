// from 0 to 768 => s
// from 768 to 960 => m
// from 960 to 1280 => l
// from 1280 to 1920 => xl
// from 1920 to infinite => xxl
export const DeviceSize = {
    small: 768,
    medium: 960,
    large: 1280,
    extraLarge: 1920,
};