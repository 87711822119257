import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';
import './App.css';
import Layout from './components/layout';
import RequireAuth from './components/requireAuth';
import { AuthProvider } from './context/AuthProvider';
import NotFoundPage from './pages/404';
import ChangePasswordPage from './pages/settings/changePassword';
import LoginPage from './pages/login';
import UnauthorizedPage from './pages/unauthorized';
import Theme from './theme';
import 'react-toastify/dist/ReactToastify.css';
import IdentityPage from './pages/identity/identity';
import MainPage from './pages/pages/main';
import AboutPage from './pages/pages/about';
import ServicesPage from './pages/pages/services';
import ServicePage from './pages/pages/service';
import PicturesPage from './pages/pages/pictures';
import PicturePage from './pages/pages/picture';
import SocialPage from './pages/identity/social';
import OpeningTimesPage from './pages/identity/openingTimes';
import MaintenancePage from './pages/maintenance';

const Container = styled.div`
  width: 100%;
  font-family: ${props => props.theme.general.globalText.fontFamilly};
  font-size: ${props => props.theme.general.globalText.fontSize};
  // added for perf
  font-display: swap;
`;

function App() {
  return (
    <Theme>
      <Container>
        <BrowserRouter>
          <AuthProvider>
            <Routes>
              <Route path="/" element={<Layout />}>
                {/* catch all */}
                <Route path='*' element={<Navigate replace to='/404' />} />

                {/* public routes */}
                <Route path="/login" element={<LoginPage />} />
                <Route path="/unauthorized" element={<UnauthorizedPage />} />

                {/* protected routes */}
                <Route element={<RequireAuth />}>
                  {/* identity */}
                  <Route path="/" element={<IdentityPage />} />
                  <Route path="/openingtimes" element={<OpeningTimesPage />} />
                  <Route path="/social" element={<SocialPage />} />
                  {/* pages */}
                  <Route path="/main" element={<MainPage />} />
                  <Route path="/about" element={<AboutPage />} />
                  <Route path="/services" element={<ServicesPage />} />
                  <Route path="/service" element={<ServicePage />} />
                  <Route path="/gallery" element={<PicturesPage />} />
                  <Route path="/picture" element={<PicturePage />} />
                  {/* maintenance */}
                  <Route path="/maintenance" element={<MaintenancePage />} />
                  {/* settings */}
                  <Route path="/changepassword" element={<ChangePasswordPage />} />
                </Route>

                {/* catch all */}
                <Route path="/404" element={<NotFoundPage />} />
              </Route>
            </Routes>
          </AuthProvider>
        </BrowserRouter>
        <ToastContainer
          theme="colored"
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable={false}
          pauseOnHover />
      </Container>
    </Theme>
  );
}

export default App;
