import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: ${props => props.theme.general.form.input.marginTop};
`;

export const InputContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

const Label = styled.label`
    font-size: ${props => props.theme.general.form.input.label.fontSize};
    margin-bottom: ${props => props.theme.general.form.input.label.marginBottom};
`

const Icon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${props => props.theme.general.form.input.icon.padding};
    background: ${props => props.theme.general.form.input.icon.backgroundColor};
    color: ${props => props.theme.general.form.input.icon.color};
    border: ${props => props.theme.general.form.input.icon.border};
`;

const Indent = styled.div`
    margin-left: ${props => props.theme.general.form.input.indent};
`

const Text = styled.textarea`
    border: ${props => props.theme.general.form.input.text.border};
    flex: 1;
    padding: ${props => props.theme.general.form.input.text.padding};

    &:focus {
        outline: none !important;
        border: ${props => props.theme.general.form.input.text.activeBorder};
    }
`;

export const ErrorContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

const Error = styled.span` 
    color: ${props => props.theme.general.form.input.error.color};
    font-size: ${props => props.theme.general.form.input.error.fontSize};
    min-height: ${props => props.theme.general.form.input.error.minHeight};
`;

interface Props {
    icon?: React.ReactNode
    indent?: boolean
    id: string
    type: string
    inputmode?: "text" | "none" | "search" | "tel" | "url" | "email" | "numeric" | "decimal"
    pattern?: string
    name: string
    label?: string
    placeholder: string
    ariaLabel: string
    value: string
    row?: number
    onChange: React.ChangeEventHandler<HTMLTextAreaElement>
    onBlur: React.FocusEventHandler<HTMLTextAreaElement>
    iRef?: React.RefObject<HTMLTextAreaElement>
    autoComplete?: string
    error: string
}

function TextArea({icon, indent, id, inputmode, label, name, placeholder, ariaLabel, value, row, onChange, onBlur, iRef, autoComplete, error}: Props) {
    return (
        <Container>
            { label &&
                <Label htmlFor={id}>{label}</Label>
            }
            <InputContainer>
                { icon && 
                    <Icon>
                        {icon}
                    </Icon>
                }
                { indent &&
                    <Indent />
                }
                <Text
                    id={id}
                    inputMode={inputmode}
                    name={name}
                    placeholder={placeholder}
                    aria-label={ariaLabel}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    ref={iRef}
                    autoComplete={autoComplete}
                    rows={row ?? 5} />
            </InputContainer>
            <ErrorContainer>
                { indent &&
                    <Indent />
                }
                <Error>{error}</Error>
            </ErrorContainer>
        </Container>
    );
}

export default TextArea;