import { IPicture } from "../../../entities/picture";
import CrudGallery from "../../crudGallery";

interface IPictureList {
    data: IPicture[];
    onAdd: () => void;
    onEdit: (index: number) => void;
    onDelete: (id: number, index: number) => void;
}

export const PictureList = (({ data, onAdd, onEdit, onDelete }: IPictureList) => {
    return (
        <CrudGallery data={data} onAdd={onAdd} onEdit={onEdit} onDelete={onDelete} />
    );
});