import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import { getMain, IGetMainRequest, IGetMainResponse, IPostMainRequest, IPostMainResponse, postMain } from "../../api/pageServices";
import { Content, ContentPadding, Section } from "../../components/common";
import { MainForm } from "../../components/forms/mainForm";
import { IMain } from "../../entities/main";
import useAuth from "../../hooks/useAuth";

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const MainPage = () => {
    const { setAuth } = useAuth();
    const [data, setData] = useState<IMain>();

    useEffect(() => {
        let ignore = false;

        const request :IGetMainRequest = {}
        const callback = (response: IGetMainResponse) => {
            if (!ignore) {
                if(response.ok) {
                    setData(response.main);
                } else {
                    if(response.unauthorized) {
                        setAuth(false);
                        sessionStorage.setItem("isLoggedIn", "0");
                        toast.error("Vous avez été déconnecté");
                    } else {
                        toast.error(response.message);
                    }
                }
            }
        };
        
        getMain(request, callback);

        return () => { ignore = true; }
    }, [setData, setAuth]);

    const onSubmit = async (request: IPostMainRequest) => {
        const callback = (response: IPostMainResponse) => {
            if (response.ok) {
                toast.success("success");
            } else {
                if(response.unauthorized) {
                    setAuth(false);
                    sessionStorage.setItem("isLoggedIn", "0");
                    toast.error("Vous avez été déconnecté");
                } else {
                    toast.error(response.message);
                }
            }
        };
        
        await postMain(request, callback);
    };

    return (
        <Section>
            <Content>
                <ContentPadding>
                    <Container>
                        <MainForm onSubmit={onSubmit} main={data} />
                    </Container>
                </ContentPadding>
            </Content>
        </Section>
    );
};

export default MainPage;