import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { BsCalendarDate, BsFillFileImageFill } from "react-icons/bs";
import { MdDescription, MdTitle } from "react-icons/md";
import styled from "styled-components";
import * as yup from "yup";
import { Form, BasicButton, FormTitle, ResponsiveRow } from "../../common";
import Input from "../input";
import { DragAndDrop } from "../../dragAndDrop";
import { AiFillStar, AiOutlineProject } from "react-icons/ai";
import { IAbout } from "../../../entities/about";
import { IMAGES_PAGES_URL } from "../../../api/pageServices";
import HtmlEditor from "../htmlEditor";

// Container
const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg"]

const validationSchema = yup.object({
    title: yup.string()
        .required("Le titre est obligatoire")
        .max(150, "Le titre ne doit pas dépasser les 150 caractères"),
    description: yup.string()
        .required("La description est obligatoire")
        .test("hasText", "La description est obligatoire",
            (value) => {
                if(!value) {
                    return false;
                }
                if(value.replace(/\s/g, "").replace(/<p><br><\/p>/g, "").replace(/<p><\/p>/g, "") === "") {
                    return false;
                }
                return true;
            }
        )
        .max(5000, "La description ne doit pas dépasser les 5000 caractères"),
    up_image: yup.bool(),
    image: yup.mixed()
        .test("fileExist", "L'image est obligatoire",
            (value, ctx) => {
                if(ctx.parent.up_image && !value) {
                    return false;
                }
                return true;
            })
        .test("fileFormat", "Le format d'image n'est pas valide (format acceptés : jpg, jpeg)", 
            value => !value || (value && SUPPORTED_FORMATS.includes(value.type))),
    years_xp: yup.string()
        .required("Le nombre d'expérience est obligatoire")
        .matches(/^[0-9]{1,2}$/, "Le nombre d'années d'expérience doit contenir 1 ou 2 chiffres"),
    projects: yup.string()
        .required("Le nombre de projets réalisés est obligatoire")
        .matches(/^[0-9]{1,5}$/, "Le nombre de projets réalisés doit contenir 1 à 5 chiffres"),
    customer_satisfaction: yup.string()
        .required("Le pourcentage de satisfaction client est obligatoire")
        .matches(/^[0-9]{1,2}$/, "Le pourcentage de satisfaction client doit contenir 1 ou 2 chiffres"),
});

interface IAboutForm {
    about?: IAbout;
    onSubmit: (request: any) => void;
}

export const AboutForm = (({ about, onSubmit }: IAboutForm) => {
    const ref = useRef() as React.MutableRefObject<HTMLInputElement>;
    const [img, setImg] = useState("");

    useEffect(() => {
        ref.current.focus();
        setImg(about ? IMAGES_PAGES_URL + about.image_url : "");
    }, [ref, about, setImg] );
    
    const formik = useFormik({
        initialValues: { 
            title: about ? about.title : "", 
            description: about ? about.description : "", 
            up_image: about ? false : true, 
            image: null, 
            years_xp: about ? about.years_xp : "", 
            projects: about ? about.projects : "", 
            customer_satisfaction: about ? about.customer_satisfaction : "" },
        enableReinitialize: true,
        validateOnBlur: true,
        onSubmit,
        validationSchema: validationSchema,
    });

    return (
        <Container>
            <FormTitle>à propos de moi</FormTitle>
            <Form onSubmit={formik.handleSubmit}>
                <Input
                    icon= {<MdTitle size={20} />}
                    id="title"
                    type="text"
                    name="title"
                    label="Titre"
                    placeholder="titre"
                    ariaLabel="title"
                    value={formik.values.title} 
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    iRef={ref}
                    error={formik.touched.title && formik.errors.title ? formik.errors.title : ""} />
                <HtmlEditor
                    icon= {<MdDescription size={20} />}
                    id="description"
                    type="text"
                    name="description"
                    label="Description"
                    placeholder="description"
                    ariaLabel="description"
                    value={formik.values.description}
                    onChange={(value) => {
                        formik.setFieldValue("description", value);
                        formik.setFieldTouched("description", true, false);
                    }}
                    onBlur={() => {
                    }}
                    error={formik.touched.description && formik.errors.description ? formik.errors.description : ""} />
                <Input
                    icon= {<BsFillFileImageFill size={20} />}
                    id="image"
                    type="file"
                    name="image"
                    label="Image"
                    placeholder="image"
                    ariaLabel="image"
                    onChange={(e) => {
                        formik.setFieldValue("up_image", true);
                        formik.setFieldValue("image", e.target.files?.[0]);
                        if(e.target.files) {
                            const file = e.target.files[0];
                            setImg(URL.createObjectURL(file));
                        }
                    }}
                    onBlur={formik.handleBlur} />
                <DragAndDrop 
                    height="512px"
                    width="400px"
                    id="image"
                    placeholder="Déposez votre image"
                    value={img}
                    onChange={(file) => {
                        formik.setFieldValue("up_image", true);
                        formik.setFieldValue("image", file, true);
                        formik.setFieldTouched("image", true, false);
                    }}
                    error={formik.touched.image && formik.errors.image ? formik.errors.image : ""}>
                </DragAndDrop>
                <ResponsiveRow>
                    <Input
                        icon= {<BsCalendarDate size={20} />}
                        id="years_xp"
                        type="text"
                        inputmode="numeric"
                        name="years_xp"
                        label="Années d'expérience"
                        placeholder="nb années d'expérience"
                        ariaLabel="years xp"
                        value={formik.values.years_xp} 
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.years_xp && formik.errors.years_xp ? formik.errors.years_xp : ""} />
                    <Input
                        icon= {<AiOutlineProject size={20} />}
                        id="projects"
                        type="text"
                        inputmode="numeric"
                        name="projects"
                        label="Projets réalisés"
                        placeholder="nb projets réalisés"
                        ariaLabel="projects"
                        value={formik.values.projects} 
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.projects && formik.errors.projects ? formik.errors.projects : ""} />
                    <Input
                        icon= {<AiFillStar size={20} />}
                        id="customer_satisfaction"
                        type="text"
                        inputmode="numeric"
                        name="customer_satisfaction"
                        label="Satisfaction client"
                        placeholder="% satisfaction client"
                        ariaLabel="customer satisfaction"
                        value={formik.values.customer_satisfaction} 
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.customer_satisfaction && formik.errors.customer_satisfaction ? formik.errors.customer_satisfaction : ""} />
                </ResponsiveRow>
                <BasicButton type="submit">Enregistrer</BasicButton>
            </Form>
        </Container>
    );
});