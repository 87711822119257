import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import { IPostPictureRequest, IPostPictureResponse, postPicture } from "../../api/pageServices";
import { Content, ContentPadding, Section } from "../../components/common";
import { PictureForm } from "../../components/forms/pictureForm";
import { IPicture } from "../../entities/picture";
import useAuth from "../../hooks/useAuth";

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

interface IPicturePage {
    picture?: IPicture
}

const PicturePage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { setAuth } = useAuth();

    const picture = (location.state as IPicturePage)?.picture;

    const onSubmit = async (request: IPostPictureRequest) => {
        const callback = (response: IPostPictureResponse) => {
            if (response.ok) {
                toast.success("success");
                navigate(-1);
            } else {
                if(response.unauthorized) {
                    setAuth(false);
                    sessionStorage.setItem("isLoggedIn", "0");
                    toast.error("Vous avez été déconnecté");
                } else {
                    toast.error("error");
                }
            }
        };
        
        request.id = picture?.id ?? 0;
        await postPicture(request, callback);
    };

    return (
        <Section>
            <Content>
                <ContentPadding>
                    <Container>
                        <PictureForm onSubmit={onSubmit} picture={picture} />
                    </Container>
                </ContentPadding>
            </Content>
        </Section>
    );
};

export default PicturePage;