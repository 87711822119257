import { useFormik } from "formik";
import { useEffect, useRef } from "react";
import { BsTextareaResize } from "react-icons/bs";
import { MdSubtitles, MdTitle } from "react-icons/md";
import styled from "styled-components";
import * as yup from "yup";
import { IMain } from "../../../entities/main";
import { Form, BasicButton, FormTitle } from "../../common";
import Input from "../input";
import TextArea from "../textarea";

// Container
const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const validationSchema = yup.object({
    title: yup.string()
        .required("Le titre est obligatoire")
        .max(150, "Le titre ne doit pas dépasser les 150 caractères"),
    subtitle: yup.string()
        .required("Le sous-titre est obligatoire")
        .max(1000, "Le sous-titre ne doit pas dépasser les 1000 caractères"),
    action_text: yup.string()
        .max(150, "Le texte d'action ne doit pas dépasser les 150 caractères"),
});

interface IMainForm {
    main?: IMain;
    onSubmit: (request: any) => void;
}

export const MainForm = (({ main, onSubmit }: IMainForm) => {
    const ref = useRef() as React.MutableRefObject<HTMLInputElement>;

    useEffect(() => {
        ref.current.focus();
    }, []);
    
    const formik = useFormik({
        initialValues: { 
            title: main ? main.title : "" , 
            subtitle: main ? main.subtitle : "" , 
            action_text: main ? main.action_text : ""},
        enableReinitialize: true,
        validateOnBlur: true,
        onSubmit,
        validationSchema: validationSchema,
    });

    return (
        <Container>
            <FormTitle>Accueil</FormTitle>
            <Form onSubmit={formik.handleSubmit}>
                <Input
                    icon= {<MdTitle size={20} />}
                    id="title"
                    type="text"
                    name="title"
                    label="Titre"
                    placeholder="titre"
                    ariaLabel="title"
                    value={formik.values.title} 
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    iRef={ref}
                    error={formik.touched.title && formik.errors.title ? formik.errors.title : ""} />
                <TextArea
                    icon= {<MdSubtitles size={20} />}
                    id="subtitle"
                    type="text"
                    name="subtitle"
                    label="Sous-titre"
                    placeholder="sous-titre"
                    ariaLabel="subtitle"
                    value={formik.values.subtitle} 
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.subtitle && formik.errors.subtitle ? formik.errors.subtitle : ""} />
                <Input
                    icon= {<BsTextareaResize size={20} />}
                    id="action_text"
                    type="text"
                    name="action_text"
                    label="Texte action"
                    placeholder="texte action"
                    ariaLabel="action text"
                    value={formik.values.action_text} 
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.action_text && formik.errors.action_text ? formik.errors.action_text : ""} />
                <BasicButton type="submit">Enregistrer</BasicButton>
            </Form>
        </Container>
    );
});