import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { logout } from "../../api/authServices";
import { IMenu } from "../../entities/menu";
import useAuth from "../../hooks/useAuth";

const Item = styled(Link)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    text-decoration: none;
    padding: ${props => props.theme.sidebar.menu.padding};
    height: ${props => props.theme.sidebar.menu.height};
    font-size: ${props => props.theme.sidebar.menu.fontSize};

    &:hover {
        background: ${props => props.theme.sidebar.menu.hover.backgroundColor};
        border-left: ${props => props.theme.sidebar.menu.hover.borderLeft};
        cursor: pointer;
    }
`;

const ItemContent = styled.div`
    display: flex;
    align-items: center;
`;

const Label = styled.span`
    font-size: ${props => props.theme.sidebar.menu.label.fontSize};
    padding: ${props => props.theme.sidebar.menu.label.padding};
`;

interface IDropdownItem {
    open: boolean
}

const DropdownItem = styled(Link)<IDropdownItem>`
    background: ${props => props.theme.sidebar.menu.submenu.backgroundColor};
    height: ${props => props.theme.sidebar.menu.submenu.height};
    padding-left: ${(p: IDropdownItem) => (p.open ? (props => props.theme.sidebar.menu.submenu.paddingLeftOpened) : (props => props.theme.sidebar.menu.submenu.paddingLeftClosed))};
    display: flex;
    align-items: center;
    text-decoration: none;
    font-size: 18px;

    &:hover {
        background: ${props => props.theme.sidebar.menu.submenu.hover.backgroundColor};
        cursor: pointer;
    }
`;

const Navigation = ({ item, open }:{item: IMenu, open: boolean}) => {
    const { setAuth } = useAuth();

    const [subnav, setSubnav] = useState(false);
    const showSubnav = () => setSubnav(!subnav);

    const callFunction = async (type: string) => {
        
        if(type === "logout") {
            const callback = () => {
                setAuth(false);
                sessionStorage.setItem("isLoggedIn", "0");
            }
            await logout(callback);
        }
    }

    return (
        <>
           <Item to={item.path} onClick={() => {
                if(item.function) {
                    callFunction(item.function);
                }
                if(item.subNav) {
                    showSubnav();
                }
            }}>
                <ItemContent>
                    {item.icon}
                    { open &&
                        <Label>{item.title}</Label>
                    }
                </ItemContent>
                <ItemContent>
                    {item.subNav && subnav
                        ? item.iconOpened
                        : item.subNav
                        ? item.iconClosed
                        : null}
                </ItemContent>
            </Item>
            { subnav && item.subNav && item.subNav.map((item, index) => {
                return (
                    <DropdownItem to={item.path} key={index} open={open}>
                        {item.icon}
                        { open &&
                            <Label>{item.title}</Label>
                        }
                    </DropdownItem>
                );
            })}
        </>
    );
};

export default Navigation;
