import { LazyLoadImage } from 'react-lazy-load-image-component';
import Spinner from "../spinner";

interface Props {
    width: number
    height: number
    alt: string
    src: string
}

function Picture({width, height, alt, src}: Props) {
    return (
        <LazyLoadImage 
            placeholder={
                <Spinner 
                    width={width} 
                    height={height}
                />
            }
            width={width} 
            height={height}
            effect="blur" 
            alt={alt} 
            src={src}
        />
    );
}

export default Picture;