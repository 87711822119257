import { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { Button, Content, ContentPadding, Section } from "../components/common";

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: ${props => props.theme.notfound.backgroundColor};
    margin: ${props => props.theme.notfound.margin};
`;

const ContainerPadding = styled.div`
    padding: ${props => props.theme.notfound.padding};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const Title = styled.h2`
    margin: 0;
    padding: 0;
    font-size: ${props => props.theme.notfound.title.fontSize};
    font-weight: ${props => props.theme.notfound.title.fontWeight};
    text-transform: ${props => props.theme.notfound.title.textTransform};
    color: ${props => props.theme.notfound.title.color};
`;

const Subtitle = styled.h2`
    font-size: ${props => props.theme.notfound.subtitle.fontSize};
    font-weight: ${props => props.theme.notfound.subtitle.fontWeight};
    text-transform: ${props => props.theme.notfound.subtitle.textTransform};
    color: ${props => props.theme.notfound.subtitle.color};
`;

const Text = styled.div`
    color: ${props => props.theme.notfound.text.color};
    margin-bottom: ${props => props.theme.notfound.text.marginBottom};
    text-align: center;
`;

const NotFoundPage = () => {
    const themeContext = useContext(ThemeContext);

    return (
        <Section>
            <Content>
                <ContentPadding>
                    <Container>
                        <ContainerPadding>
                            <Title>404</Title>
                            <Subtitle>oups... vous vous êtes perdu(e)</Subtitle>
                            <Text>Désolé mais la page que vous recherchez n'existe pas ou plus.</Text>
                            <Button 
                                backgroundColor={themeContext.notfound.button.backgroundColor}
                                color={themeContext.notfound.button.color}
                                aria-label="identity"
                                to="/">Retourner à l'accueil</Button>
                        </ContainerPadding>
                    </Container>
                </ContentPadding>
            </Content>
        </Section>
    );
};

export default NotFoundPage;