import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import { IPostServiceRequest, IPostServiceResponse, postService } from "../../api/pageServices";
import { Content, ContentPadding, Section } from "../../components/common";
import { ServiceForm } from "../../components/forms/serviceForm";
import useAuth from "../../hooks/useAuth";
import { IService } from "../../entities/service"

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

interface IServicePage {
    service?: IService
}

const ServicePage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { setAuth } = useAuth();

    const service = (location.state as IServicePage)?.service;

    const onSubmit = async (request: IPostServiceRequest) => {
        const callback = (response: IPostServiceResponse) => {
            if (response.ok) {
                toast.success("Le service a été mise à jour");
                navigate(-1);
            } else {
                if(response.unauthorized) {
                    setAuth(false);
                    sessionStorage.setItem("isLoggedIn", "0");
                    toast.error("Vous avez été déconnecté");
                } else {
                    toast.error(response.message);
                }
            }
        };
        
        request.id = service?.id ?? 0;
        await postService(request, callback);
    };

    return (
        <Section>
            <Content>
                <ContentPadding>
                    <Container>
                        <ServiceForm onSubmit={onSubmit} service={service} />
                    </Container>
                </ContentPadding>
            </Content>
        </Section>
    );
};

export default ServicePage;