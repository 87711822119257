import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import { getMaintenance, IGetMaintenanceRequest, IGetMaintenanceResponse, IPostMaintenanceRequest, IPostMaintenanceResponse, postMaintenance } from "../api/maintenanceServices";
import { Content, ContentPadding, Section } from "../components/common";
import { MaintenanceForm } from "../components/forms/maintenanceForm";
import { IMaintenance } from "../entities/maintenance";
import useAuth from "../hooks/useAuth";

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const MaintenancePage = () => {
    const { setAuth } = useAuth();
    const [data, setData] = useState<IMaintenance>();

    useEffect(() => {
        let ignore = false;

        const request :IGetMaintenanceRequest = {}
        const callback = (response: IGetMaintenanceResponse) => {
            if (!ignore) {
                if(response.ok) {
                    setData(response.maintenance);
                } else {
                    if(response.unauthorized) {
                        setAuth(false);
                        sessionStorage.setItem("isLoggedIn", "0");
                        toast.error("Vous avez été déconnecté");
                    } else {
                        toast.error(response.message);
                    }
                }
            }
        };
        
        getMaintenance(request, callback);

        return () => { ignore = true; }
    }, [setData, setAuth]);

    const onSubmit = async (request: IPostMaintenanceRequest) => {
        const callback = (response: IPostMaintenanceResponse) => {
            if (response.ok) {
                toast.success("Votre site a été mis à jour");
            } else {
                if(response.unauthorized) {
                    setAuth(false);
                    sessionStorage.setItem("isLoggedIn", "0");
                    toast.error("Vous avez été déconnecté");
                } else {
                    toast.error(response.message);
                }
            }
        };

        await postMaintenance(request, callback);
    };

    return (
        <Section>
            <Content>
                <ContentPadding>
                    <Container>
                        <MaintenanceForm maintenance={data} onSubmit={onSubmit}/>
                    </Container>
                </ContentPadding>
            </Content>
        </Section>
    );
};

export default MaintenancePage;