import styled from "styled-components";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: ${props => props.theme.general.form.input.marginTop};
`;

export const InputContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

const Label = styled.label`
    font-size: ${props => props.theme.general.form.input.label.fontSize};
    margin-bottom: ${props => props.theme.general.form.input.label.marginBottom};
`

const Icon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${props => props.theme.general.form.input.icon.padding};
    background: ${props => props.theme.general.form.input.icon.backgroundColor};
    color: ${props => props.theme.general.form.input.icon.color};
    border: ${props => props.theme.general.form.input.icon.border};
`;

const Indent = styled.div`
    margin-left: ${props => props.theme.general.form.input.indent};
`;

export const ErrorContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

const Error = styled.span` 
    color: ${props => props.theme.general.form.input.error.color};
    font-size: ${props => props.theme.general.form.input.error.fontSize};
    min-height: ${props => props.theme.general.form.input.error.minHeight};
`;

const Text = styled(ReactQuill)`
    :focus-within {
        border: 1px solid #000;

        > .ql-toolbar {
            border: none !important; 
            border-bottom: 1px solid #ccc !important; 
        }

        > .ql-container {
            border: none !important; 
        }
    }
`;

const reactQuillStyle = {
    height: "100%",
    width: "100%",
};

interface Props {
    icon?: React.ReactNode
    indent?: boolean
    id: string
    type: string
    inputmode?: "text" | "none" | "search" | "tel" | "url" | "email" | "numeric" | "decimal"
    pattern?: string
    name: string
    label?: string
    placeholder: string
    ariaLabel: string
    value: string
    onChange: (value: string) => void;
    onBlur: (e: any) => void;
    iRef?: React.RefObject<ReactQuill>
    error: string
}

function HtmlEditor({icon, indent, id, inputmode, label, name, placeholder, ariaLabel, value, onChange, onBlur, iRef, error}: Props) {
    const modules = {
        clipboard: {
            matchVisual: false
        },
        toolbar: [
          [{ 'header': [1, 2, false] }],
          ['bold', 'italic', 'underline'],
          [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}]
        ],
      };
    
    const  formats = [
        'header',
        'bold', 'italic', 'underline',
        'list', 'bullet', 'indent'
    ];

    return (
        <Container>
            
            { label &&
                <Label htmlFor={id}>{label}</Label>
            }
            <InputContainer>
                { icon && 
                    <Icon>
                        {icon}
                    </Icon>
                }
                { indent &&
                    <Indent />
                }
                <Text
                    style={reactQuillStyle}
                    theme="snow"
                    modules={modules}
                    formats={formats}
                    id={id}
                    placeholder={placeholder}
                    aria-label={ariaLabel}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    ref={iRef}/>
            </InputContainer>
            <ErrorContainer>
                { indent &&
                    <Indent />
                }
                <Error>{error}</Error>
            </ErrorContainer>
        </Container>
    );
}

export default HtmlEditor;