import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import { getIdentity, IGetIdentityRequest, IGetIdentityResponse, IPostIdentityRequest, IPostIdentityResponse, postIdentity } from "../../api/identityServices";
import { Content, ContentPadding, Section } from "../../components/common";
import { IdentityForm } from "../../components/forms/identityForm";
import { IIdentity } from "../../entities/identity";
import useAuth from "../../hooks/useAuth";

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const IdentityPage = () => {
    const { setAuth } = useAuth();
    const [data, setData] = useState<IIdentity>();

    useEffect(() => {
        let ignore = false;

        const request :IGetIdentityRequest = {}
        const callback = (response: IGetIdentityResponse) => {
            if (!ignore) {
                if(response.ok) {
                    setData(response.identity);
                } else {
                    if(response.unauthorized) {
                        setAuth(false);
                        sessionStorage.setItem("isLoggedIn", "0");
                        toast.error("Vous avez été déconnecté");
                    } else {
                        toast.error(response.message);
                    }
                }
            }
        };
        
        getIdentity(request, callback);

        return () => { ignore = true; }
    }, [setData, setAuth]);

    const onSubmit = async (request: IPostIdentityRequest) => {
        const callback = (response: IPostIdentityResponse) => {
            if (response.ok) {
                toast.success("Votre identité a été mise à jour");
            } else {
                if(response.unauthorized) {
                    setAuth(false);
                    sessionStorage.setItem("isLoggedIn", "0");
                    toast.error("Vous avez été déconnecté");
                } else {
                    toast.error(response.message);
                }
            }
        };

        await postIdentity(request, callback);
    };

    return (
        <Section>
            <Content>
                <ContentPadding>
                    <Container>
                        <IdentityForm onSubmit={onSubmit} identity={data} />
                    </Container>
                </ContentPadding>
            </Content>
        </Section>
    );
};

export default IdentityPage;