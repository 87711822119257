import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import { getOpeningTimes, IGetOpeningTimesRequest, IGetOpeningTimesResponse, IPostOpeningTimesRequest, IPostSocialResponse, postOpeningTimes } from "../../api/identityServices";
import { Content, ContentPadding, Section } from "../../components/common";
import { OpeningTimesForm } from "../../components/forms/openingTimesForm";
import { IOpeningTimes } from "../../entities/openingTimes";
import useAuth from "../../hooks/useAuth";

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const OpeningTimesPage = () => {
    const { setAuth } = useAuth();
    const [data, setData] = useState<IOpeningTimes[]>();

    useEffect(() => {
        let ignore = false;

        const request :IGetOpeningTimesRequest = {}
        const callback = (response: IGetOpeningTimesResponse) => {
            if (!ignore) {
                if(response.ok) {
                    setData(response.days);
                } else {
                    if(response.unauthorized) {
                        setAuth(false);
                        sessionStorage.setItem("isLoggedIn", "0");
                        toast.error("Vous avez été déconnecté");
                    } else {
                        toast.error(response.message);
                    }
                }
            }
        };
        
        getOpeningTimes(request, callback);

        return () => { ignore = true; }
    }, [setData, setAuth]);

    const onSubmit = async (request: IPostOpeningTimesRequest) => {
        const callback = (response: IPostSocialResponse) => {
            if (response.ok) {
                toast.success("Vos horaires ont été mis à jour");
            } else {
                if(response.unauthorized) {
                    setAuth(false);
                    sessionStorage.setItem("isLoggedIn", "0");
                    toast.error("Vous avez été déconnecté");
                } else {
                    toast.error(response.message);
                }
            }
        };

        await postOpeningTimes(request, callback);
    };

    return (
        <Section>
            <Content>
                <ContentPadding>
                    <Container>
                        <OpeningTimesForm onSubmit={onSubmit} openingTimes={data} />
                    </Container>
                </ContentPadding>
            </Content>
        </Section>
    );
};

export default OpeningTimesPage;