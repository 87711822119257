import { post } from "./helper";
import { IResponse } from "./response";

const LOGIN_URL = "/auth-api/login.php"
const LOGOUT_URL = "/auth-api/logout.php"

export interface ILoginRequest {
    username: string;
    password: string;
}

export interface ILoginResponse extends IResponse {
    changePassword?: boolean;
}

export async function login(data: ILoginRequest, callback: ((response: ILoginResponse) => void)) {  
    const postCallBack = (response: any) => {
        if (response.ok) {
            callback({ 
                ok: response?.ok, 
                message: response?.message,
                changePassword: response?.data?.change_password,
            });
        } else {
            callback(response);
        }
    };

    await post(LOGIN_URL, data, postCallBack);
}

export async function logout(callback: (() => void)) {
    const postCallback = (response: any) => {
        callback();
    };

    await post(LOGOUT_URL, null, postCallback);
}