import styled from "styled-components";
import { Link } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import Navigation from "./navigation";
import { Menu } from "../../entities/menu";

interface ISidebarNav {
    open: boolean
}

const SidebarNav = styled.nav`
    background: ${props => props.theme.sidebar.backgroundColor};
    min-width: ${(p: ISidebarNav) => (p.open ? (props => props.theme.sidebar.widthOpened) : (props => props.theme.sidebar.widthClosed))};
    width: ${(p: ISidebarNav) => (p.open ? (props => props.theme.sidebar.widthOpened) : (props => props.theme.sidebar.widthClosed))};
    height: 100vh;
    display: flex;
    justify-content: center;
    position: ${props => props.theme.sidebar.position};
    left: ${(p: ISidebarNav) => (p.open ? (props => props.theme.sidebar.leftOpened) : (props => props.theme.sidebar.leftClosed))};
    transition: 350ms;
    z-index: 10;
    color: ${props => props.theme.sidebar.fontColor};
`;

const SidebarWrap = styled.div`
    width: 100%;
`;

const NavIcon = styled(Link)`
    margin-right: ${props => props.theme.sidebar.navIcon.marginLeft};
    font-size: ${props => props.theme.sidebar.navIcon.size};
    height: ${props => props.theme.sidebar.navIcon.height};
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

interface ISidebar {
    isOpen: boolean
    toggle: () => void
}

const Sidebar = ({isOpen, toggle}: ISidebar) => {
    return (
        <SidebarNav open={isOpen}>
            <SidebarWrap>
                <NavIcon to="#">
                    { isOpen 
                        ? <AiIcons.AiOutlineClose onClick={toggle} />
                        : <FaIcons.FaBars onClick={toggle} />
                    }
                </NavIcon>
                {Menu.map((item, index) => {
                    return <Navigation item={item} open={isOpen} key={index} />;
                })}
            </SidebarWrap>
        </SidebarNav>
    );
};

export default Sidebar;
