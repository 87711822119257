import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: ${props => props.theme.general.form.input.marginTop};
`;

export const LabelContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

const Label = styled.label`
    font-size: ${props => props.theme.general.form.input.label.fontSize};
    margin-bottom: ${props => props.theme.general.form.input.label.marginBottom};
`;

export const InputContainer = styled.div`
    display: flex;
    flex-direction: row;

    &.checkbox {
        border: 1px solid rgba(28,28,28,0.2);
        /* align-items: center; */
    }
`;

const Icon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${props => props.theme.general.form.input.icon.padding};
    background: ${props => props.theme.general.form.input.icon.backgroundColor};
    color: ${props => props.theme.general.form.input.icon.color};
    border: ${props => props.theme.general.form.input.icon.border};
`;

const Indent = styled.div`
    margin-left: ${props => props.theme.general.form.input.indent};
`;

const Text = styled.input`
    border: ${props => props.theme.general.form.input.text.border};
    flex: 1;
    padding: ${props => props.theme.general.form.input.text.padding};

    &:focus {
        outline: none !important;
        border: ${props => props.theme.general.form.input.text.activeBorder};
    }

    &.checkbox {
        flex: inherit !important;
        margin-left: 10px;
    }
`;

const CheckboxLabel = styled.label`
    font-size: ${props => props.theme.general.form.input.label.fontSize};
    padding: ${props => props.theme.general.form.input.text.padding};
`;

export const ErrorContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

const Error = styled.span` 
    color: ${props => props.theme.general.form.input.error.color};
    font-size: ${props => props.theme.general.form.input.error.fontSize};
    min-height: ${props => props.theme.general.form.input.error.minHeight};
`;

interface Props {
    icon?: React.ReactNode;
    indent?: boolean;
    id: string;
    type: string;
    inputmode?: "text" | "none" | "search" | "tel" | "url" | "email" | "numeric" | "decimal";
    name: string;
    label?: string;
    placeholder: string;
    ariaLabel: string;
    value?: string;
    checked?: boolean;
    onChange: React.ChangeEventHandler<HTMLInputElement>;
    onBlur: React.FocusEventHandler<HTMLInputElement>;
    iRef?: React.RefObject<HTMLInputElement>;
    autoComplete?: string;
    error?: string;
}

function Input({icon, indent, id, type, inputmode, label, name, placeholder, ariaLabel, value, checked, onChange, onBlur, iRef, autoComplete, error}: Props) {
    return (
        <Container>
            <LabelContainer>
                { indent &&
                    <Indent />
                }
                { label &&
                    <Label htmlFor={id}>{label}</Label>
                }
            </LabelContainer>
            <InputContainer className={type === "checkbox" ? "checkbox" : ""}>
                { icon && 
                    <Icon>
                        {icon}
                    </Icon>
                }
                { indent &&
                    <Indent />
                }
                <Text className={type === "checkbox" ? "checkbox" : ""}
                    id={id}
                    type={type}
                    inputMode={inputmode}
                    name={name}
                    placeholder={placeholder}
                    aria-label={ariaLabel}
                    value={value}
                    checked={checked}
                    onChange={onChange}
                    onBlur={onBlur}
                    ref={iRef}
                    autoComplete={autoComplete} />
                {type === "checkbox" &&
                    <CheckboxLabel htmlFor={id}>{placeholder}</CheckboxLabel>
                }
            </InputContainer>
            <ErrorContainer>
                { indent &&
                    <Indent />
                }
                <Error>{error}</Error>
            </ErrorContainer>
        </Container>
    );
}

export default Input;