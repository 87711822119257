import { useFormik } from "formik";
import React, { useContext, useEffect, useRef } from "react";
import { RiLockPasswordFill, RiShieldCheckFill } from "react-icons/ri"
import { BsFillShieldLockFill } from "react-icons/bs" 
import { Form, BasicButton, FormTitle } from "../../common";
import * as yup from "yup";
import styled, { ThemeContext } from "styled-components";
import Input from "../input";

const validationSchema = yup.object({
    old_password: yup.string()
        .required("L'ancien mot de passe est obligatoire"),
    new_password: yup.string()
        .required("Le nouveau mot de passe est obligatoire")
        .min(8, 'Le mot de passe doit contenir 8 caractères minimum')
        .max(150, 'Le mot de passe ne peut pas excéder 150 caractères'),
    confirm_password: yup.string()
        .oneOf([yup.ref('new_password'), null], 'Le mot de passe est différent'),
});

// Container
const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

interface IChangePasswordForm {
    onSubmit: (request: any) =>  void;
}

export const ChangePasswordForm = (({onSubmit}: IChangePasswordForm) => {
    const themeContext = useContext(ThemeContext);
    const ref = useRef() as React.MutableRefObject<HTMLInputElement>;

    useEffect(() => {
        ref.current.focus();
    }, []);
    
    const formik = useFormik({
        initialValues: { old_password: "", new_password: "", confirm_password: "" },
        validateOnBlur: true,
        onSubmit,
        validationSchema: validationSchema,
    });

    return (
        <Container>
            <FormTitle>Changement de mot de passe</FormTitle>
            <Form onSubmit={formik.handleSubmit} maxWidth={themeContext.changePassword.maxWidth}>
                <Input
                    icon= {<BsFillShieldLockFill size={20} />}
                    id="old_password"
                    type="password"
                    name="old_password"
                    placeholder="Ancien mot de passe"
                    ariaLabel="old password"
                    value={formik.values.old_password} 
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    iRef={ref}
                    error={formik.touched.old_password && formik.errors.old_password ? formik.errors.old_password : ""} />
                <Input
                    icon= {<RiLockPasswordFill size={20} />}
                    id="new_password"
                    type="password"
                    name="new_password"
                    placeholder="Nouveau mot de passe"
                    ariaLabel="new password"
                    value={formik.values.new_password} 
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.new_password && formik.errors.new_password ? formik.errors.new_password : ""} />
                <Input
                    icon= {<RiShieldCheckFill size={20} />}
                    id="confirm_password"
                    type="password"
                    name="confirm_password"
                    placeholder="Confirmation nouveau mot de passe"
                    ariaLabel="confirm password"
                    value={formik.values.confirm_password} 
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.confirm_password && formik.errors.confirm_password ? formik.errors.confirm_password : ""} />
                <BasicButton type="submit">Modifier</BasicButton>
            </Form>
        </Container>
    );
});