import { useFormik } from "formik";
import { useEffect, useRef } from "react";
import { FaDiscord, FaFacebookF, FaInstagram, FaLinkedinIn, FaPinterestP, FaSkype, FaTiktok, FaTwitch, FaTwitter, FaYoutube } from "react-icons/fa";
import styled from "styled-components";
import * as yup from "yup";
import { ISocial } from "../../../entities/social";
import { Form, BasicButton, FormTitle, ResponsiveRow } from "../../common";
import Input from "../input";

// Container
const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const validationSchema = yup.object({
    linkedin: yup.string()
        .matches(/(?:https?:)?\/\/(?:[\w]+\.)?linkedin\.com\/in\/(?<permalink>[\w\-_À-ÿ%]+)\/?/, "Le lien linkedin n'est pas valide")
        .max(150, "Le lien linkedin ne peut pas excéder 150 caractères"),
    facebook: yup.string()
        .matches(/(?:https?:\/\/)?(?:www\.)?facebook\.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w-]*\/)*([\w\-.]*)/, "Le lien facebook n'est pas valide")
        .max(150, "Le lien facebook ne peut pas excéder 150 caractères"),
    twitter: yup.string()
        .matches(/(?:https?:)?\/\/(?:[A-z]+\.)?twitter\.com\/@?(?!home|share|privacy|tos)(?<username>[A-z0-9_]+)\/?/, "Le lien twitter n'est pas valide")
        .max(150, "Le lien twitter ne peut pas excéder 150 caractères"),
    skype: yup.string()
        .matches(/(?:(?:callto|skype):)(?<username>[a-z][a-z0-9.,\-_]{5,31})(?:\?(?:add|call|chat|sendfile|userinfo))?/, "Le lien skype n'est pas valide")
        .max(150, "Le lien skype ne peut pas excéder 150 caractères"),
    instagram: yup.string()
        .matches(/(?:https?:)?\/\/(?:www\.)?(?:instagram\.com|instagr\.am)\/(?<username>[A-Za-z0-9_](?:(?:[A-Za-z0-9_]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_]))?)/, "Le lien instagram n'est pas valide")
        .max(150, "Le lien instagram ne peut pas excéder 150 caractères"),
    youtube: yup.string()
        .matches(/(?:https?:)?\/\/(?:[A-z]+\.)?youtube.com\/user\/(?<username>[A-z0-9]+)\/?/, "Le lien youtube n'est pas valide")
        .max(150, "Le lien youtube ne peut pas excéder 150 caractères"),
    tiktok: yup.string()
        .matches(/^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/, "Le lien tiktok n'est pas valide")
        .max(150, "Le lien tiktok ne peut pas excéder 150 caractères"),
    pinterest: yup.string()
        .matches(/^http(s)?:\/\/pinterest\.com\/(.*)?$/, "Le lien pinterest n'est pas valide")
        .max(150, "Le lien pinterest ne peut pas excéder 150 caractères"),
    discord: yup.string()
        .matches(/^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/, "Le lien discord n'est pas valide")
        .max(150, "Le lien discord ne peut pas excéder 150 caractères"),
    twitch: yup.string()
        .matches(/^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/, "Le lien twitch n'est pas valide")
        .max(150, "Le lien twitch ne peut pas excéder 150 caractères"),
});

interface ISocialForm {
    social?: ISocial,
    onSubmit: (request: any) => void;
}

export const SocialForm = (({ social, onSubmit }: ISocialForm) => {
    const ref = useRef() as React.MutableRefObject<HTMLInputElement>;

    useEffect(() => {
        ref.current.focus();
    }, [ref]);
    
    const formik = useFormik({
        initialValues: { 
            linkedin: social ? social.linkedin : "",
            facebook: social ? social.facebook : "",
            twitter: social ? social.twitter : "",
            skype: social ? social.skype : "",
            instagram: social ? social.instagram : "",
            youtube: social ? social.youtube : "",
            tiktok: social ? social.tiktok : "",
            pinterest: social ? social.pinterest : "",
            discord: social ? social.discord : "",
            twitch: social ? social.twitch : ""
        },
        enableReinitialize: true,
        validateOnBlur: true,
        onSubmit,
        validationSchema: validationSchema,
    });

    return (
        <Container>
            <FormTitle>Réseaux sociaux</FormTitle>
            <Form onSubmit={formik.handleSubmit}>
                <ResponsiveRow>
                    <Input
                        icon={<FaLinkedinIn size={20} />}
                        id="linkedin"
                        type="text"
                        name="linkedin"
                        label="Lien Linkedin"
                        placeholder="lien Linkedin https://"
                        ariaLabel="linkedin url"
                        iRef={ref}
                        value={formik.values.linkedin} 
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.linkedin && formik.errors.linkedin ? formik.errors.linkedin : ""} />
                    <Input
                        icon={<FaFacebookF size={20} />}
                        id="facebook"
                        type="text"
                        name="facebook"
                        label="Lien Facebook"
                        placeholder="lien Facebook https://"
                        ariaLabel="facebook url"
                        value={formik.values.facebook} 
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.facebook && formik.errors.facebook ? formik.errors.facebook : ""} />
                </ResponsiveRow>
                <ResponsiveRow>
                    <Input
                        icon={<FaTwitter size={20} />}
                        id="twitter"
                        type="text"
                        name="twitter"
                        label="Lien Twitter"
                        placeholder="lien Twitter https://"
                        ariaLabel="twitter url"
                        value={formik.values.twitter} 
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.twitter && formik.errors.twitter ? formik.errors.twitter : ""} />
                    <Input
                        icon={<FaSkype size={20} />}
                        id="skype"
                        type="text"
                        name="skype"
                        label="Lien Skype"
                        placeholder="lien Skype https://"
                        ariaLabel="skype url"
                        value={formik.values.skype} 
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.skype && formik.errors.skype ? formik.errors.skype : ""} />
                </ResponsiveRow>
                <ResponsiveRow>
                    <Input
                        icon={<FaInstagram size={20} />}
                        id="instagram"
                        type="text"
                        name="instagram"
                        label="Lien Instagram"
                        placeholder="lien Instagram https://"
                        ariaLabel="instagram url"
                        value={formik.values.instagram} 
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.instagram && formik.errors.instagram ? formik.errors.instagram : ""} />
                    <Input
                        icon={<FaYoutube size={20} />}
                        id="youtube"
                        type="text"
                        name="youtube"
                        label="Lien Youtube"
                        placeholder="lien Youtube https://"
                        ariaLabel="youtube url"
                        value={formik.values.youtube} 
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.youtube && formik.errors.youtube ? formik.errors.youtube : ""} />
                </ResponsiveRow>
                <ResponsiveRow>
                    <Input
                        icon={<FaTiktok size={20} />}
                        id="tiktok"
                        type="text"
                        name="tiktok"
                        label="Lien Tiktok"
                        placeholder="lien Tiktok https://"
                        ariaLabel="tiktok url"
                        value={formik.values.tiktok} 
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.tiktok && formik.errors.tiktok ? formik.errors.tiktok : ""} />
                    <Input
                        icon={<FaPinterestP size={20} />}
                        id="pinterest"
                        type="text"
                        name="pinterest"
                        label="Lien Pinterest"
                        placeholder="lien Pinterest https://"
                        ariaLabel="pinterest url"
                        value={formik.values.tiktok} 
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.pinterest && formik.errors.pinterest ? formik.errors.pinterest : ""} />
                </ResponsiveRow>
                <ResponsiveRow>
                    <Input
                        icon={<FaDiscord size={20} />}
                        id="discord"
                        type="text"
                        name="discord"
                        label="Lien Discord"
                        placeholder="lien Discord https://"
                        ariaLabel="discord url"
                        value={formik.values.discord} 
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.discord && formik.errors.discord ? formik.errors.discord : ""} />
                    <Input
                        icon={<FaTwitch size={20} />}
                        id="twitch"
                        type="text"
                        name="twitch"
                        label="Lien Twitch"
                        placeholder="lien Twitch https://"
                        ariaLabel="twitch url"
                        value={formik.values.twitch} 
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.twitch && formik.errors.twitch ? formik.errors.twitch : ""} />
                </ResponsiveRow>
                <BasicButton type="submit">Enregistrer</BasicButton>
            </Form>
        </Container>
    );
});