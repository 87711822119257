import React from "react";
import styled from "styled-components";

interface Props {
    width: number
    height: number
}

const SpinnerContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00000078;
    width: ${(props: Props) => props.width + "px" || "32px"};
    height: ${(props: Props) => props.height + "px" || "32px"};
`;

const Container = styled.div`
    box-sizing: border-box;
    border: 7px solid ${props => props.theme.spinner.backColor};
    border-top: 7px solid ${props => props.theme.spinner.spinColor};
    border-radius: 50%;
    width: ${(props: Props) => (props.width/2) + "px" || "32px"};
    height: ${(props: Props) => (props.height/2) + "px" || "32px"};
    animation: spin 1s linear infinite;

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
`;

const Spinner = ({width, height} :Props) => <SpinnerContainer width={width} height={height}><Container width={width} height={height}/></SpinnerContainer>;

export default Spinner;