import React from "react"
import { useMediaQuery } from "react-responsive";
import { ThemeProvider } from "styled-components";
import { hexToRGB } from "./utils/colorHelper";
import { DeviceSize } from "./utils/deviceSize";

enum Device {
    xxl,
    xl,
    l,
    m,
    s,
}

const theme = (device: Device) => {
    const colors = {
        white: "#ffffff",
        black: "#000000",
        primary: "#1c1c1c",
        onPrimary: "#ffffff",
        secondary: "#dd341f",
        onSecondary: "#ffffff",
        tertiary: "#f7f7f7",
        onTertiary: "#000000",
        error: "#ff5252",
        onError: "#000000",
    };

    return {
        // GENERAL
        general: {
            ////////////////////////////////////////
            //////// PAGE
            ////////////////////////////////////////
            page: {
                xxlWidth: "1200px",
                xlWidth: "950px",
                lWidth: "650px",
                mWidth: "450px",
                // padding-top same as TOPBAR height + 50ppx (80 + 50)
                padding: "130px 20px 0 20px",
            },
            ////////////////////////////////////////
            //////// TEXT
            ////////////////////////////////////////
            globalText : {
                fontFamilly: ["'Montserrat', sans-serif"],
                fontSize: "16px",
            },
            ////////////////////////////////////////
            //////// FORM
            ////////////////////////////////////////
            form: {
                title: {
                    marginBottom: "50px",
                    fontSize: "20px",
                    fontWeight: "700",
                    textTransform: "uppercase",
                },
                input: {
                    marginTop: "10px",
                    label: {
                        fontSize: "12px",
                        marginBottom: "10px",
                    },
                    icon: {
                        padding: "0 10px",
                        backgroundColor: colors.primary,
                        color: colors.onPrimary,
                        border: `1px solid ${colors.primary}`,
                    },
                    // = icon padding left and right + icon border left and right + icon size (10x2 + 1x2 + 20)
                    indent: "42px",
                    text: {
                        border: `1px solid ${hexToRGB(colors.primary, 0.2)}`,
                        padding: "10px",
                        activeBorder: `1px solid ${colors.primary}`,
                    },
                    error: {
                        color: colors.error,
                        fontSize: "11px",
                        minHeight: "20px",
                    },
                },   
            },
            ////////////////////////////////////////
            //////// BUTTON
            ////////////////////////////////////////
            button: {
                padding: "12px 30px",
                fontWeight: 700,
                backgroundColor: colors.primary,
                color: colors.onPrimary,
                borderRadius: "5px",
                transition: "0.2s all",
                hoverOpacity: "0.8",
            }
        },
        // SPINNER
        spinner: {
            backColor: colors.onPrimary,
            spinColor: colors.primary,
        },
        // LOGIN FORM
        login: {
            maxWidth: "400px",
            border: "1px solid #c7c7c7",
            padding: device === Device.s ? "15px" : "20px",
            title: {
                marginBottom: "50px",
                textTransform: "uppercase",
            }, 
        },
        // TOPBAR
        topbar: {
            backgroundColor: "#000",
            height: "80px",
            fontColor: "#ffffff",
            navIcon: {
                marginLeft: "20px",
                size: "30px",
            },
        },
        // SIDEBAR
        sidebar: {
            backgroundColor: "#15171c",
            fontColor: "#ffffff",
            widthOpened: "250px",
            widthClosed: "70px",
            position: (device === Device.s || device === Device.m) ? "fixed" : "relative",
            leftOpened: "0",
            leftClosed: (device === Device.s || device === Device.m) ? "-100%" : "0",
            navIcon: {
                marginLeft: "20px",
                size: "30px",
                // same as TOPBAR
                height: "80px",
            },
            menu: {
                padding: "20px",
                height: "60px",
                fontSize: "20px",
                hover: {
                    backgroundColor: "#252831",
                    borderLeft: "4px solid #ffffff",
                },
                label: {
                    fontSize: "16px",
                    padding: "0 16px",
                },
                submenu: {
                    backgroundColor: "#252831",
                    height: "60px",
                    paddingLeftOpened: "50px",
                    paddingLeftClosed: "25px",
                    hover: {
                        backgroundColor: "#00a149",
                    }
                }
            },
        },
        // CHANGE PASSWORD FORM
        changePassword: {
            maxWidth: "400px",       
        },
        // 404 PAGE
        notfound: {
            backgroundColor: colors.black,
            margin: device === Device.m || device === Device.s ? "30px 0" : "110px 0",
            padding: "30px 10px",
            title: {
                fontSize: device === Device.m || device === Device.s ? "70px" : "100px",
                fontWeight: 700,
                textTransform: "uppercase",
                color: colors.white,
            },
            subtitle: {
                fontSize: device === Device.m || device === Device.s ? "16px" : "25px",
                fontWeight: 700,
                textTransform: "uppercase",
                color: colors.white,
            },
            text: {
                color: colors.white,
                marginBottom: "30px",
            },
            button: {
                backgroundColor: colors.white,
                color: colors.black,
            }
        },
    };
};

interface Props {
    children?: React.ReactNode
}

function Theme({ children } : Props) {
    const isXXL = useMediaQuery({ minWidth: DeviceSize.extraLarge });
    const isXL = useMediaQuery({ minWidth: DeviceSize.large, maxWidth: DeviceSize.extraLarge });
    const isL = useMediaQuery({ minWidth: DeviceSize.medium, maxWidth: DeviceSize.large });
    const isM = useMediaQuery({ minWidth: DeviceSize.small, maxWidth: DeviceSize.medium });
    const device = isXXL ? Device.xxl : (isXL ? Device.xl : (isL ? Device.l : (isM ? Device.m : Device.s)));
  
    return <ThemeProvider theme={theme(device)}>{children}</ThemeProvider>;
}
  
export default Theme;