import { useFormik } from "formik";
import { useEffect, useRef } from "react";
import { BiTime } from "react-icons/bi";
import styled from "styled-components";
import * as yup from "yup";
import { IOpeningTimes } from "../../../entities/openingTimes";
import { Form, BasicButton, FormTitle, ResponsiveRow } from "../../common";
import Input from "../input";

// Container
const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const validationSchema = yup.object({
    monday_times: yup.string()
        .max(100, "Les horaires ne doivent pas dépasser les 100 caractères"),
    tuesday_times: yup.string()
        .max(100, "Les horaires ne doivent pas dépasser les 100 caractères"),
    wednesday_times: yup.string()
        .max(100, "Les horaires ne doivent pas dépasser les 100 caractères"),
    thursday_times: yup.string()
        .max(100, "Les horaires ne doivent pas dépasser les 100 caractères"),
    friday_times: yup.string()
        .max(100, "Les horaires ne doivent pas dépasser les 100 caractères"),
    saturday_times: yup.string()
        .max(100, "Les horaires ne doivent pas dépasser les 100 caractères"),
    sunday_times: yup.string()
        .max(100, "Les horaires ne doivent pas dépasser les 100 caractères"),
});

interface IOpeningTimesForm {
    openingTimes?: IOpeningTimes[];
    onSubmit: (request: any) => void;
}

export const OpeningTimesForm = (({ openingTimes, onSubmit }: IOpeningTimesForm) => {
    const ref = useRef() as React.MutableRefObject<HTMLInputElement>;

    useEffect(() => {
        ref.current.focus();
    }, []);
    
    const formik = useFormik({
        initialValues: { 
            monday_times: openingTimes ? openingTimes?.find(({day}) => day === "monday")?.times ?? "" : "",
            tuesday_times: openingTimes ? openingTimes?.find(({day}) => day === "tuesday")?.times ?? "" : "",
            wednesday_times: openingTimes ? openingTimes?.find(({day}) => day === "wednesday")?.times ?? "" : "",
            thursday_times: openingTimes ? openingTimes?.find(({day}) => day === "thursday")?.times ?? "" : "",
            friday_times: openingTimes ? openingTimes?.find(({day}) => day === "friday")?.times ?? "" : "",
            saturday_times: openingTimes ? openingTimes?.find(({day}) => day === "saturday")?.times ?? "" : "",
            sunday_times: openingTimes ? openingTimes?.find(({day}) => day === "sunday")?.times ?? "" : ""},
        enableReinitialize: true,
        validateOnBlur: true,
        onSubmit,
        validationSchema: validationSchema,
    });

    return (
        <Container>
            <FormTitle>Horaires d'ouverture</FormTitle>
            <Form onSubmit={formik.handleSubmit}>
                <ResponsiveRow>
                    <Input
                        icon= {<BiTime size={20} />}
                        id="monday_times"
                        type="text"
                        name="monday_times"
                        label="Lundi"
                        placeholder="horaires 08:00-12:00 13:00-18:00"
                        ariaLabel="monday times"
                        value={formik.values.monday_times} 
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        iRef={ref}
                        error={formik.touched.monday_times && formik.errors.monday_times ? formik.errors.monday_times : ""} />
                    <Input
                        icon= {<BiTime size={20} />}
                        id="tuesday_times"
                        type="text"
                        name="tuesday_times"
                        label="Mardi"
                        placeholder="horaires 08:00-12:00 13:00-18:00"
                        ariaLabel="tuesday times"
                        value={formik.values.tuesday_times} 
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.tuesday_times && formik.errors.tuesday_times ? formik.errors.tuesday_times : ""} />
                </ResponsiveRow>
                <ResponsiveRow>
                    <Input
                        icon= {<BiTime size={20} />}
                        id="wednesday_times"
                        type="text"
                        name="wednesday_times"
                        label="Mercredi"
                        placeholder="horaires 08:00-12:00 13:00-18:00"
                        ariaLabel="wednesday times"
                        value={formik.values.wednesday_times} 
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.wednesday_times && formik.errors.wednesday_times ? formik.errors.wednesday_times : ""} />
                    <Input
                        icon= {<BiTime size={20} />}
                        id="thursday_times"
                        type="text"
                        name="thursday_times"
                        label="Jeudi"
                        placeholder="horaires 08:00-12:00 13:00-18:00"
                        ariaLabel="thursday times"
                        value={formik.values.thursday_times} 
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.thursday_times && formik.errors.thursday_times ? formik.errors.thursday_times : ""} />
                </ResponsiveRow>
                <ResponsiveRow>
                    <Input
                        icon= {<BiTime size={20} />}
                        id="friday_times"
                        type="text"
                        name="friday_times"
                        label="Vendredi"
                        placeholder="horaires 08:00-12:00 13:00-18:00"
                        ariaLabel="friday times"
                        value={formik.values.friday_times} 
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.friday_times && formik.errors.friday_times ? formik.errors.friday_times : ""} />
                    <Input
                        icon= {<BiTime size={20} />}
                        id="saturday_times"
                        type="text"
                        name="saturday_times"
                        label="Samedi"
                        placeholder="horaires 08:00-12:00 13:00-18:00"
                        ariaLabel="saturday times"
                        value={formik.values.saturday_times} 
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.saturday_times && formik.errors.saturday_times ? formik.errors.saturday_times : ""} />
                </ResponsiveRow>
                <Input
                    icon= {<BiTime size={20} />}
                    id="sunday_times"
                    type="text"
                    name="sunday_times"
                    label="Dimanche"
                    placeholder="horaires 08:00-12:00 13:00-18:00"
                    ariaLabel="dimache times"
                    value={formik.values.sunday_times} 
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.sunday_times && formik.errors.sunday_times ? formik.errors.sunday_times : ""} />
                <BasicButton type="submit">Enregistrer</BasicButton>
            </Form>
        </Container>
    );
});