import { MdNoPhotography } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import styled from "styled-components";
import { IMAGES_PICTURES_URL } from "../../api/pageServices";
import { IPicture } from "../../entities/picture";
import { BasicButton } from "../common";
import Picture from "../picture";

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const Marginer = styled.div`
  height: 20px;
`;

const ImagesContainer = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
`;

const ImageContainer = styled.div`
    width: 200px;
    height: 200px;
    padding: 1px;
    position: relative;
    /* overflow: hidden; */
    cursor: pointer;
`;

const DescriptionContainer = styled.div`
    /* remove 1px left and 1px right of parent controller */
    width: calc(100% - 2px);
    display: flex;
    position: absolute;
    background-color: #00000078;
    color: white;
`;

const Description = styled.div`
    width: 100%;
    padding: 10px;
`;

const ActionIcon = styled.div`
    position: absolute;
    background-color: #00000078;
    color: white;
    padding: 10px;
    bottom: 5px;
    /* remove 1px right of parent controller */
    right: 1px;
    cursor: pointer;
`;

const Image = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00000078;
`

const NoImage = styled.div`
    display: flex;
    position: absolute;
    z-index: -1;
`

interface ICrudGallery {
    data: IPicture[];
    onAdd: () => void;
    onEdit: (index: number) => void;
    onDelete: (id: number, index: number) => void;
}

const CrudGallery = (({ data, onAdd, onEdit, onDelete }: ICrudGallery) => {
    return (
        <Container>
            <BasicButton onClick={onAdd}>Ajouter</BasicButton>
            <Marginer/>
            <ImagesContainer>
                {data.map((picture, index) => {
                    return (
                        <ImageContainer key={picture.id} onClick={(e) => {
                                onEdit(index); 
                            }}>
                            <DescriptionContainer>
                                <Description>{picture.description}</Description>
                            </DescriptionContainer>
                            <ActionIcon>
                                <RiDeleteBin6Line size={20} onClick={(e) => {                             
                                    e.preventDefault();
                                    e.stopPropagation();
                                    onDelete(picture.id, index); 
                                }}/>
                            </ActionIcon>
                            <Image>
                                <Picture width={200} height={200} alt={picture.id.toString()} src={IMAGES_PICTURES_URL + picture.image_url} />
                                <NoImage>
                                    <MdNoPhotography size={30} />
                                </NoImage>
                            </Image>
                        </ImageContainer>
                    );
                })}
            </ImagesContainer>
        </Container>
    );
});

export default CrudGallery;