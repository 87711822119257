import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { BsFillFileImageFill } from "react-icons/bs";
import { MdDescription } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import * as yup from "yup";
import { IMAGES_PICTURES_URL } from "../../../api/pageServices";
import { IPicture } from "../../../entities/picture";
import { BasicButton, Form, FormTitle, ResponsiveRow } from "../../common";
import { DragAndDrop } from "../../dragAndDrop";
import Input from "../input";

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg"];

const validationSchema = yup.object({
    description: yup.string()
        .required("La description est obligatoire.")
        .max(100, "La description ne peut pas exéder 100 caractères"),
    up_image: yup.bool(),
    image: yup.mixed()
        .test("fileExist", "L'image est obligatoire",
            (value, ctx) => {
                if(ctx.parent.up_image && !value) {
                    return false;
                }
                return true;
            })
        .test("fileFormat", "Le format d'image n'est pas valide (format acceptés : jpg, jpeg)", 
            value => !value || (value && SUPPORTED_FORMATS.includes(value.type))),
});

interface IPictureForm {
    picture? : IPicture;
    onSubmit: (request: any) => void;
}

export const PictureForm = (({ picture, onSubmit }: IPictureForm) => {
    const navigate = useNavigate();
    const ref = useRef() as React.MutableRefObject<HTMLInputElement>;
    const [img, setImg] = useState(picture ? IMAGES_PICTURES_URL + picture.image_url : "");

    useEffect(() => {
        ref.current.focus();
    }, [ref]);
    
    const formik = useFormik({
        initialValues: { 
            description: picture ? picture.description : "",
            up_image: picture ? false : true, 
            image: null },
        validateOnBlur: true,
        onSubmit,
        validationSchema: validationSchema,
    });

    const onBack = () => {
        navigate(-1);
    }

    return (
        <Container>
            <FormTitle>Image</FormTitle>
            <Form onSubmit={formik.handleSubmit}>
                <Input
                    icon= {<MdDescription size={20} />}
                    id="description"
                    type="text"
                    name="description"
                    label="Description"
                    placeholder="description"
                    ariaLabel="description"
                    value={formik.values.description} 
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    iRef={ref}
                    error={formik.touched.description && formik.errors.description ? formik.errors.description : ""} />
                <Input
                    icon= {<BsFillFileImageFill size={20} />}
                    id="image"
                    type="file"
                    name="image"
                    label="Image"
                    placeholder="image"
                    ariaLabel="image"
                    onChange={(e) => {
                        formik.setFieldValue("up_image", true);
                        formik.setFieldValue("image", e.target.files?.[0]);
                        if(e.target.files) {
                            const file = e.target.files[0];
                            setImg(URL.createObjectURL(file));
                        }
                    }}
                    onBlur={formik.handleBlur} />
                <DragAndDrop
                    height="512px"
                    width="400px"
                    id="image"
                    placeholder="Déposez votre image"
                    value={img}
                    onChange={(file) => {
                        formik.setFieldValue("up_image", true);
                        formik.setFieldValue("image", file, true);
                        formik.setFieldTouched("image", true, false);
                    }}
                    error={formik.touched.image && formik.errors.image ? formik.errors.image : ""}>
                </DragAndDrop>
                <ResponsiveRow>
                    <BasicButton onClick={onBack}>Annuler</BasicButton>
                    <BasicButton type="submit">Enregistrer</BasicButton>
                </ResponsiveRow>
            </Form>
        </Container>
    );
});