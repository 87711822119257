import { useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import styled from "styled-components";
import useAuth from "../../hooks/useAuth"
import Sidebar from "../sidebar";
import { Topbar } from "../topbar";

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`

const Content = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
`

const RequireAuth = () => {
    const { auth } = useAuth();
    const location = useLocation();

    const [sidebar, setSidebar] = useState(false);
    const showSidebar = () => setSidebar(!sidebar);
    
    return (
        auth
            ? <Container>
                <Topbar toggle={showSidebar}/>
                <Content>
                    <Sidebar isOpen={sidebar} toggle={showSidebar}/>
                    <Outlet />
                </Content>
            </Container>
            : <Navigate to="/login" state={{ from: location }} replace />
    );
}

export default RequireAuth;