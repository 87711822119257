import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import { getSocial, IGetSocialRequest, IGetSocialResponse, IPostSocialRequest, IPostSocialResponse, postSocial } from "../../api/identityServices";
import { Content, ContentPadding, Section } from "../../components/common";
import { SocialForm } from "../../components/forms/socialForm";
import { ISocial } from "../../entities/social";
import useAuth from "../../hooks/useAuth";

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const SocialPage = () => {
    const { setAuth } = useAuth();
    const [data, setData] = useState<ISocial>();

    useEffect(() => {
        let ignore = false;

        const request :IGetSocialRequest = {}
        const callback = (response: IGetSocialResponse) => {
            if (!ignore) {
                if(response.ok) {
                    setData(response.social);
                } else {
                    if(response.unauthorized) {
                        setAuth(false);
                        sessionStorage.setItem("isLoggedIn", "0");
                        toast.error("Vous avez été déconnecté");
                    } else {
                        toast.error(response.message);
                    }
                }
            }
        };
        
        getSocial(request, callback);

        return () => { ignore = true; }
    }, [setData, setAuth]);

    const onSubmit = async (request: IPostSocialRequest) => {
        const callback = (response: IPostSocialResponse) => {
            if (response.ok) {
                toast.success("Vos réseaux sociaux ont été mis à jour");
            } else {
                if(response.unauthorized) {
                    setAuth(false);
                    sessionStorage.setItem("isLoggedIn", "0");
                    toast.error("Vous avez été déconnecté");
                } else {
                    toast.error(response.message);
                }
            }
        };

        await postSocial(request, callback);
    };

    return (
        <Section>
            <Content>
                <ContentPadding>
                    <Container>
                        <SocialForm onSubmit={onSubmit} social={data} />
                    </Container>
                </ContentPadding>
            </Content>
        </Section>
    );
};

export default SocialPage;