import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components';

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

interface IDragConstainer {
    dragging: boolean;
    height: string;
    width: string;
}

const DragConstainer = styled.div`
    display: flex;
    flex-direction: column;
    width: ${(p: IDragConstainer) => p.width};
    height: ${(p: IDragConstainer) => p.height};
    border: ${(p: IDragConstainer) => p.dragging ?  "dashed grey 4px" : "dashed grey 2px"};
    background-color: rgba(255,255,255,.8);
    align-items: center;
    justify-content: center;
`;

const TextExt = styled.div`
    display: flex;
`;

const Text = styled.div`
    display: flex;
    position: absolute;
    z-index: -1;
`;

interface IImage {
    height: string;
    width: string;
}

const Image = styled.img`
    width: ${(p: IImage) => p.width};
    height: ${(p: IImage) => p.height};
    display: flex;
    object-fit: cover;
`;

const Error = styled.span` 
    color: ${props => props.theme.general.form.input.error.color};
    font-size: ${props => props.theme.general.form.input.error.fontSize};
    min-height: ${props => props.theme.general.form.input.error.minHeight};
`;

interface IProps {
    height: string;
    width: string;
    id: string;
    placeholder: string;
    placeholderOut?: boolean;
    value: string;
    onChange: (file: File) => void;
    error: string;
    children?: React.ReactNode;
}

export const DragAndDrop = (({ height, width, id, placeholder, placeholderOut, value, onChange, error, children }: IProps) => {
    const dropRef = useRef() as React.MutableRefObject<HTMLDivElement>;
    const [img, setImg] = useState(value);

    useEffect(() => {
        setImg(value);
    }, [value, setImg]);

    const [dragging, setDragging] = useState(false);

    const handleDragStart = (e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
        e.dataTransfer?.clearData();
    }

    const handleDrag = (e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
    }

    const handleDragIn = (e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.dataTransfer !== null && e.dataTransfer.items && e.dataTransfer.items.length > 0) { 
            setDragging(true);
            // dropRef.current.focus();
        }
    }

    const handleDragOut = (e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(false);
    }

    const handleDrop = (e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(false);
        if (e.dataTransfer !== null && e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            setImg(URL.createObjectURL(e.dataTransfer.files[0]));
            onChange(e.dataTransfer.files[0]);
        }
    }

    useEffect(() => {
        let div = dropRef.current;

        // componentDidMount
        div.addEventListener("dragstart", handleDragStart);
        div.addEventListener("dragenter", handleDragIn);
        div.addEventListener("dragleave", handleDragOut);
        div.addEventListener("dragover", handleDrag);
        div.addEventListener("drop", handleDrop);

        // componentWillUnmount
        return () => {
            div.removeEventListener("dragenter", handleDragStart);
            div.removeEventListener("dragenter", handleDragIn);
            div.removeEventListener("dragleave", handleDragOut);
            div.removeEventListener("dragover", handleDrag);
            div.removeEventListener("drop", handleDrop);
        }
    });

    return (
        <Container>
            {placeholderOut &&
                <TextExt>{placeholder}</TextExt>
            }
            <DragConstainer id={id} ref={dropRef} dragging={dragging} height={height} width={width}>
                {!placeholderOut &&
                    <Text>{placeholder}</Text>
                }
                <Image height={height} width={width} src={img} alt="" />
            </DragConstainer>
            <Error>{error}</Error>
        </Container>
    );
});