import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import { deletePicture, getAllPictures, IDeletePictureRequest, IDeletePictureResponse, IGetAllPicturesRequest, IGetAllPicturesResponse } from "../../api/pageServices";
import { Content, ContentPadding, FormTitle, Section } from "../../components/common";
import { PictureList } from "../../components/lists/pictureList";
import { IPicture } from "../../entities/picture";
import useAuth from "../../hooks/useAuth";

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const PicturesPage = () => {
    const navigate = useNavigate();
    const { setAuth } = useAuth();
    const [data, setData] = useState<IPicture[]>([]);

    useEffect(() => {
        let ignore = false;

        const request :IGetAllPicturesRequest = {}
        const callback = (response: IGetAllPicturesResponse) => {
            if (!ignore) {
                if(response.ok) {
                    setData(response.pictures);
                } else {
                    if(response.unauthorized) {
                        setAuth(false);
                        sessionStorage.setItem("isLoggedIn", "0");
                        toast.error("Vous avez été déconnecté");
                    } else {
                        toast.error(response.message);
                    }
                }
            }
        };
        
        getAllPictures(request, callback);

        return () => { ignore = true; }
    }, [setData, setAuth]);

    const onAdd = () => {
        navigate("/picture", { state: { picture: undefined } });
    };

    const onEdit = (index: number) => {
        navigate("/picture", { state: { picture: data[index] } });
    };

    const onDelete = async (id: number, index: number) => {
        const request :IDeletePictureRequest = {
            id: id,
        }
        const callback = (response: IDeletePictureResponse) => {
            if (response.ok) {
                toast.success("Le service a été supprimé");
                const newData = data.slice();
                newData.splice(index, 1);
                setData(newData);
                
            } else {
                if(response.unauthorized) {
                    setAuth(false);
                    sessionStorage.setItem("isLoggedIn", "0");
                    toast.error("Vous avez été déconnecté");
                } else {
                    toast.error(response.message);
                }
            }
        };
        
        await deletePicture(request, callback);
    };

    return (
        <Section>
            <Content>
                <ContentPadding>
                    <Container>
                        <FormTitle>Galerie d'images</FormTitle>
                        <PictureList data={data} onAdd={onAdd} onEdit={onEdit} onDelete={onDelete} />
                    </Container>
                </ContentPadding>
            </Content>
        </Section>
    );
};

export default PicturesPage;