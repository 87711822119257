import { Link } from "react-router-dom";
import styled from "styled-components";
import * as FaIcons from "react-icons/fa";
import { useMediaQuery } from "react-responsive";
import { DeviceSize } from "../../utils/deviceSize";

const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    background: ${props => props.theme.topbar.backgroundColor};
    height: ${props => props.theme.topbar.height};
    color: ${props => props.theme.topbar.fontColor};
`

const NavIcon = styled(Link)`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    left: 0;
    margin-left: ${props => props.theme.topbar.navIcon.marginLeft};
    font-size: ${props => props.theme.topbar.navIcon.size};
`;

const Title = styled.h1`
    text-align: center;
`

interface ITopbar {
    toggle: () => void
}

export function Topbar({toggle}: ITopbar) {
    const isSmall = useMediaQuery({ maxWidth: DeviceSize.small });
    const isMedium = useMediaQuery({ minWidth: DeviceSize.small, maxWidth: DeviceSize.large });

    return (
        <Container>
            { (isSmall || isMedium) &&
                <NavIcon to="#">
                    <FaIcons.FaBars onClick={toggle} />
                </NavIcon>
            }
            <Title>
                WebAdmin
            </Title>
        </Container>
    );
}