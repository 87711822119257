import { useFormik } from "formik";
import { useEffect, useRef } from "react";
import { AiFillCloud, AiFillPhone } from "react-icons/ai";
import { BsShop } from "react-icons/bs";
import { ImLocation } from "react-icons/im";
import { MdDescription, MdEmail, MdOutlineWorkOutline } from "react-icons/md";
import styled from "styled-components";
import * as yup from "yup";
import { IIdentity } from "../../../entities/identity";
import { Form, BasicButton, FormTitle, ResponsiveRow } from "../../common";
import Input from "../input";
import TextArea from "../textarea";

// Container
const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const validationSchema = yup.object({
    name: yup.string()
        .required("Le nom est obligatoire")
        .max(100, "Le nom ne peut pas excéder 38 caractères"),
    work: yup.string()
        .required("Le métier est obligatoire")
        .max(200, "Le nom ne peut pas excéder 200 caractères"),
    work_description: yup.string()
        .required("La description de métier est obligatoire")
        .max(2000, "La description de métier ne peut pas excéder 2000 caractères"),
    address_1: yup.string()
        .required("L'adresse est obligatoire")
        .max(38, "L'adresse ne peut pas excéder 38 caractères"),
    address_2: yup.string()
        .max(38, "L'adresse complémentaire ne peut pas excéder 38 caractères"),
    zip_code: yup.string()
        .required("Le code postal est obligatoire")
        .matches(/^[0-9]{5}$/, "Le code postal doit contenir 5 chiffres"),
    city: yup.string()
        .required("La ville est obligatoire")
        .max(32, "La ville ne peut pas excéder 32 caractères"),
    phone_number: yup.string()
        .required("Le numéro de téléphone est obligatoire")
        .matches(/^0[1-9]([0-9]{2})([0-9]{2})([0-9]{2})([0-9]{2})$/, "Le numéro de téléphone n'est pas valide"),
        // with space authorized
        // .matches(/^0[1-9](?:[\s.-]*\d{2}){4}$/, "Le numéro de téléphone n'est pas valide"),
    email: yup.string()
        .required("L'email est obligatoire")
        .email("L'email n'est pas valide")
        .max(150, "L'email ne peut pas excéder 150 caractères"),
    website_url: yup.string()
        .required("L'url du site internet est obligatoire")
        .matches(/^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/, "L'url n'est pas valide")
        .max(150, "L'url du site internet ne peut pas excéder 150 caractères"),
});

interface IIdentityForm {
    identity?: IIdentity,
    onSubmit: (request: any) => void;
}

export const IdentityForm = (({ identity, onSubmit }: IIdentityForm) => {
    const ref = useRef() as React.MutableRefObject<HTMLInputElement>;

    useEffect(() => {
        ref.current.focus();
    }, [ref]);
    
    const formik = useFormik({
        initialValues: { 
            name: identity ? identity.name : "" , 
            work: identity ? identity.work : "", 
            work_description: identity ? identity.work_description : "",
            address_1: identity ? identity.address_1 : "", 
            address_2: identity ? identity.address_2 : "", 
            zip_code: identity ? identity.zip_code : "", 
            city: identity ? identity.city : "", 
            phone_number: identity ? identity.phone_number : "", 
            email: identity ? identity.email : "", 
            website_url: identity ? identity.website_url : "",
        },
        enableReinitialize: true,
        validateOnBlur: true,
        onSubmit,
        validationSchema: validationSchema,
    });

    return (
        <Container>
            <FormTitle>Identité</FormTitle>
            <Form onSubmit={formik.handleSubmit}>
                <Input
                    icon= {<BsShop size={20} />}
                    id="name"
                    type="text"
                    name="name"
                    label="Nom"
                    placeholder="nom"
                    ariaLabel="name"
                    value={formik.values.name} 
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    iRef={ref}
                    error={formik.touched.name && formik.errors.name ? formik.errors.name : ""} />
                <Input
                    icon= {<MdOutlineWorkOutline size={20} />}
                    id="work"
                    type="text"
                    name="work"
                    label="Métier"
                    placeholder="métier"
                    ariaLabel="work"
                    value={formik.values.work} 
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.work && formik.errors.work ? formik.errors.work : ""} />
                <TextArea
                    icon= {<MdDescription size={20} />}
                    id="work_description"
                    type="text"
                    name="work_description"
                    label="Descriptif du métier"
                    placeholder="description"
                    ariaLabel="work description"
                    value={formik.values.work_description}
                    row={15}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.work_description && formik.errors.work_description ? formik.errors.work_description : ""} />
                <Input
                    icon= {<ImLocation size={20} />}
                    id="address_1"
                    type="text"
                    name="address_1"
                    label="Adresse"
                    placeholder="adresse"
                    ariaLabel="address 1"
                    value={formik.values.address_1} 
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.address_1 && formik.errors.address_1 ? formik.errors.address_1 : ""} />
                <Input
                    indent={true}
                    id="address_2"
                    type="text"
                    name="address_2"
                    label="Adresse complémentaire"
                    placeholder="adresse complémentaire"
                    ariaLabel="address 2"
                    value={formik.values.address_2} 
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.address_2 && formik.errors.address_2 ? formik.errors.address_2 : ""} />
                <ResponsiveRow>
                    <Input
                        indent={true}
                        id="zip_code"
                        type="text"
                        inputmode="numeric"
                        name="zip_code"
                        label="Code postal"
                        placeholder="code postal"
                        ariaLabel="zip code"
                        value={formik.values.zip_code} 
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.zip_code && formik.errors.zip_code ? formik.errors.zip_code : ""} />
                    <Input
                        indent={true}
                        id="city"
                        type="text"
                        name="city"
                        label="Ville"
                        placeholder="ville"
                        ariaLabel="city"
                        value={formik.values.city} 
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.city && formik.errors.city ? formik.errors.city : ""} />
                </ResponsiveRow>
                <ResponsiveRow>
                    <Input
                        icon={<AiFillPhone size={20} />}
                        id="phone_number"
                        type="text"
                        inputmode="tel"
                        name="phone_number"
                        label="Numéro de téléphone"
                        placeholder="numéro de téléphone"
                        ariaLabel="phone number"
                        value={formik.values.phone_number} 
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.phone_number && formik.errors.phone_number ? formik.errors.phone_number : ""} />
                    <Input
                        icon={<MdEmail size={20} />}
                        id="email"
                        type="text"
                        name="email"
                        label="Adresse email"
                        placeholder="adresse email"
                        ariaLabel="email"
                        value={formik.values.email} 
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.email && formik.errors.email ? formik.errors.email : ""} />
                </ResponsiveRow>
                <Input
                    icon={<AiFillCloud size={20} />}
                    id="website_url"
                    type="text"
                    name="website_url"
                    label="Site internet"
                    placeholder="site internet https://"
                    ariaLabel="website url"
                    value={formik.values.website_url} 
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.website_url && formik.errors.website_url ? formik.errors.website_url : ""} />
                <BasicButton type="submit">Enregistrer</BasicButton>
            </Form>
        </Container>
    );
});