import React from "react";
import styled from "styled-components";
import { Content, ContentPadding, Section } from "../components/common";

const Container = styled.div`
    width: 100%;
    height: 3000px;
    display: flex;
    flex-direction: column;
`;

const UnauthorizedPage = () => {
    return (
        <Section>
            <Content>
                <ContentPadding>
                    <Container>
                    UNAUTHORIZED
                    </Container>
                </ContentPadding>
            </Content>
        </Section>
    );
};

export default UnauthorizedPage;