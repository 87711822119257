import { post } from "./helper";
import { IResponse } from "./response";

const CHANGE_PASSWORD_URL = "/auth-api/change_password.php"

export interface IChangePasswordRequest {
    username: string;
    password: string;
}

export interface IChangePasswordResponse extends IResponse {
}

export async function changePassword(data: IChangePasswordRequest, callback: ((response: IChangePasswordResponse) => void)) {
    const postCallBack = (response: any) => {
        callback(response);
    };

    post(CHANGE_PASSWORD_URL, data, postCallBack);
}