import React from "react";
import * as AiIcons from "react-icons/ai";
import * as RiIcons from "react-icons/ri";
import * as MdIcons from "react-icons/md";
import * as FaIcons from "react-icons/fa";
import * as BsIcons from "react-icons/bs";
import * as SiIcons from "react-icons/si";
import * as IoIcons from "react-icons/io5";
import * as BiIcons from "react-icons/bi";

export interface ISubNav {
    title: string;
    path: string;
    icon: React.ReactNode;
}

export interface IMenu {
    title: string;
    path: string;
    function?: string;
    icon: React.ReactNode;
    iconClosed?: React.ReactNode;
    iconOpened?: React.ReactNode;
    subNav?: ISubNav[];
}

export const Menu : IMenu[] = [
    {
        title: "Identité",
        path: "#",
        icon: <FaIcons.FaStore />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        subNav: [
            {
                title: "Informations",
                path: "/",
                icon: <FaIcons.FaUserAlt />,
            },
            {
                title: "Horaires",
                path: "/openingtimes",
                icon: <BiIcons.BiTime />,
            },
            {
                title: "Réseaux sociaux",
                path: "/social",
                icon: <IoIcons.IoShareSocial />,
            },
        ],
    },
    {
        title: "Pages",
        path: "#",
        icon: <BsIcons.BsReverseLayoutTextSidebarReverse />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        subNav: [
            {
                title: "Accueil",
                path: "/main",
                icon: <AiIcons.AiFillHome />,
            },
            {
                title: "À propos",
                path: "/about",
                icon: <SiIcons.SiAboutdotme />,
            },
            {
                title: "Services",
                path: "/services",
                icon: <RiIcons.RiCustomerService2Fill />,
            },
            {
                title: "Gallerie",
                path: "/gallery",
                icon: <BsIcons.BsImages />,
            },
        ],
    },
    {
        title: "Maintenance",
        path: "/maintenance",
        icon: <AiIcons.AiFillTool />,
    },
    {
        title: "Paramètres",
        path: "#",
        icon: <RiIcons.RiSettings4Fill />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        subNav: [
            {
                title: "Changement de mot de passe",
                path: "/changepassword",
                icon: <MdIcons.MdPassword />,
            },
        ],
    },
    {
        title: "Se déconnecter",
        path: "#",
        function: "logout",
        icon: <RiIcons.RiShutDownLine />,
    }
];