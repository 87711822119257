import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import { getAbout, IGetAboutRequest, IGetAboutResponse, IPostAboutRequest, IPostAboutResponse, postAbout } from "../../api/pageServices";
import { Content, ContentPadding, Section } from "../../components/common";
import { AboutForm } from "../../components/forms/aboutForm";
import { IAbout } from "../../entities/about";
import useAuth from "../../hooks/useAuth";

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const AboutPage = () => {
    const { setAuth } = useAuth();
    const [data, setData] = useState<IAbout>();

    useEffect(() =>  {
        let ignore = false;

        const request :IGetAboutRequest = {}
        const callback = (response: IGetAboutResponse) => {
            if (!ignore) {
                if(response.ok) {
                    setData(response.about);
                } else {
                    if(response.unauthorized) {
                        setAuth(false);
                        sessionStorage.setItem("isLoggedIn", "0");
                        toast.error("Vous avez été déconnecté");
                    } else {
                        toast.error(response.message);
                    }
                }
            }
        };
        
        getAbout(request, callback);

        return () => { ignore = true; }
    }, [setData, setAuth]);

    const onSubmit = async (request: IPostAboutRequest) => {
        const callback = (response: IPostAboutResponse) => {
            if (response.ok) {
                toast.success("La page 'à propos' a été mise à jour");
            } else {
                if(response.unauthorized) {
                    setAuth(false);
                    sessionStorage.setItem("isLoggedIn", "0");
                    toast.error("Vous avez été déconnecté");
                } else {
                    toast.error(response.message);
                }
            }
        };
        
        await postAbout(request, callback);
    };

    return (
        <Section>
            <Content>
                <ContentPadding>
                    <Container>
                        <AboutForm onSubmit={onSubmit} about={data} />
                    </Container>
                </ContentPadding>
            </Content>
        </Section>
    );
};

export default AboutPage;