import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { BsFillFileImageFill } from "react-icons/bs";
import { MdDescription } from "react-icons/md";
import { RiCustomerService2Fill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import * as yup from "yup";
import { BasicButton, Form, FormTitle, ResponsiveRow } from "../../common";
import { DragAndDrop } from "../../dragAndDrop";
import Input from "../input";
// import TextArea from "../textarea";
import { IService } from "../../../entities/service";
import { ICONS_SERVICES_URL, IMAGES_SERVICES_URL } from "../../../api/pageServices";
import { BiImageAlt } from "react-icons/bi";
import HtmlEditor from "../htmlEditor";


// Container
const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const SUPPORTED_ICON_FORMATS = ["image/png"];
const SUPPORTED_IMAGE_FORMATS = ["image/jpg", "image/jpeg"];

const validationSchema = yup.object({
    up_icon: yup.bool(),
    icon: yup.mixed()
        .test("fileExist", "L'icone est obligatoire",
            (value, ctx) => {
                if(ctx.parent.up_icon && !value) {
                    return false;
                }
                return true;
            })
        .test("fileFormat", "Le format de l'icone n'est pas valide (format acceptés : png)", 
            value => !value || (value && SUPPORTED_ICON_FORMATS.includes(value.type))),
    title: yup.string()
        .required("Le titre est obligatoire.")
        .max(150, "Le titre ne peut pas exéder 150 caractères"),
    summary: yup.string()
        // .required("Le résumé est obligatoire.")
        .max(500, "Le résumé ne peut pas exéder 500 caractères"),
    description: yup.string()
        .required("La description est obligatoire")
        .test("hasText", "La description est obligatoire",
            (value) => {
                if(!value) {
                    return false;
                }
                if(value.replace(/\s/g, "").replace(/<p><br><\/p>/g, "").replace(/<p><\/p>/g, "") === "") {
                    return false;
                }
                return true;
            }
        )
        .max(5000, "La description ne peut pas exéder 5000 caractères"),
    up_image: yup.bool(),
    image: yup.mixed()
        .test("fileExist", "L'image est obligatoire",
            (value, ctx) => {
                if(ctx.parent.up_image && !value) {
                    return false;
                }
                return true;
            }
        )
        .test("fileFormat", "Le format d'image n'est pas valide (format acceptés : jpg, jpeg)", 
            value => !value || (value && SUPPORTED_IMAGE_FORMATS.includes(value.type))),
});

interface IServiceForm {
    service?: IService;
    onSubmit: (request: any) => void;
}

export const ServiceForm = (({ service, onSubmit }: IServiceForm) => {
    const navigate = useNavigate();
    const ref = useRef() as React.MutableRefObject<HTMLInputElement>;
    const [icon, setIcon] = useState(service ? ICONS_SERVICES_URL + service.icon_url : "");
    const [img, setImg] = useState(service ? IMAGES_SERVICES_URL + service.image_url : "");
    
    useEffect(() => {
        ref.current.focus();
    }, [ref]);

    const formik = useFormik({
        initialValues: { 
            up_icon: service ? false : true, 
            icon: null,
            title: service ? service.title : "", 
            summary: service ? service.summary : "", 
            description: service ? service.description : "",
            up_image: service ? false : true, 
            image: null },
        validateOnBlur: true,
        onSubmit,
        validationSchema: validationSchema,
    });

    const onBack = () => {
        navigate(-1);
    }

    return (
        <Container>
            <FormTitle>service</FormTitle>
            <Form onSubmit={formik.handleSubmit}>
                <Input
                    icon= {<BiImageAlt size={20} />}
                    id="icon"
                    type="file"
                    name="icon"
                    label="Icône"
                    placeholder="icône"
                    ariaLabel="icon"
                    onChange={(e) => {
                        formik.setFieldValue("up_icon", true);
                        formik.setFieldValue("icon", e.target.files?.[0]);
                        if(e.target.files) {
                            const file = e.target.files[0];
                            setIcon(URL.createObjectURL(file));
                        }
                    }}
                    onBlur={formik.handleBlur} />
                <DragAndDrop 
                    height="64px"
                    width="64px"
                    id="icon"
                    placeholder="Déposez votre icône"
                    placeholderOut
                    value={icon}
                    onChange={(file) => {
                        formik.setFieldValue("up_icon", true);
                        formik.setFieldValue("icon", file, true);
                        formik.setFieldTouched("icon", true, false);
                    }}
                    error={formik.touched.icon && formik.errors.icon ? formik.errors.icon : ""}>
                </DragAndDrop>
                <Input
                    icon= {<RiCustomerService2Fill size={20} />}
                    id="title"
                    type="text"
                    name="title"
                    label="Intitulé"
                    placeholder="intitulé du service"
                    ariaLabel="service title"
                    value={formik.values.title} 
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    iRef={ref}
                    error={formik.touched.title && formik.errors.title ? formik.errors.title : ""} />
                {/* <TextArea
                    icon= {<MdSubtitles size={20} />}
                    id="summary"
                    type="text"
                    name="summary"
                    label="Résumé"
                    placeholder="résumé"
                    ariaLabel="summary"
                    value={formik.values.summary}
                    row={10}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.summary && formik.errors.summary ? formik.errors.summary : ""} /> */}
                <HtmlEditor
                    icon= {<MdDescription size={20} />}
                    id="description"
                    type="text"
                    name="description"
                    label="Description"
                    placeholder="description"
                    ariaLabel="description"
                    value={formik.values.description}
                    onChange={(value) => {
                        formik.setFieldValue("description", value);
                        formik.setFieldTouched("description", true, false);
                    }}
                    onBlur={() => {
                    }}
                    error={formik.touched.description && formik.errors.description ? formik.errors.description : ""} />
                <Input
                    icon= {<BsFillFileImageFill size={20} />}
                    id="image"
                    type="file"
                    name="image"
                    label="Image"
                    placeholder="Image"
                    ariaLabel="image"
                    onChange={(e) => {
                        formik.setFieldValue("up_image", true);
                        formik.setFieldValue("image", e.target.files?.[0]);
                        if(e.target.files) {
                            const file = e.target.files[0];
                            setImg(URL.createObjectURL(file));
                        }
                    }}
                    onBlur={formik.handleBlur} />
                <DragAndDrop 
                    height="512px"
                    width="400px"
                    id="image"
                    placeholder="Déposez votre image"
                    value={img}
                    onChange={(file) => {
                        formik.setFieldValue("up_image", true);
                        formik.setFieldValue("image", file, true);
                        formik.setFieldTouched("image", true, false);
                    }}
                    error={formik.touched.image && formik.errors.image ? formik.errors.image : ""}>
                </DragAndDrop>
                <ResponsiveRow>
                    <BasicButton type="button" onClick={onBack}>Annuler</BasicButton>
                    <BasicButton type="submit">Enregistrer</BasicButton>
                </ResponsiveRow>
            </Form>
        </Container>
    );
});