import { IService } from "../../../entities/service";
import CrudTable, { ICrudTableColumn } from "../../crudTable";

interface IServiceList {
    data: IService[];
    onAdd: () => void;
    onEdit: (index: number) => void;
    onDelete: (index: number) => void;
}

export const ServiceList = (({ data, onAdd, onEdit, onDelete }: IServiceList) => {
    const columns : ICrudTableColumn[] = [
      {
          Header: "Service",
          accessor: "title",
      },
      {
          Header: "Résumé",
          accessor: "summary",
      },
    ];

    return (
        <CrudTable columns={columns} data={data} onAdd={onAdd} onEdit={onEdit} onDelete={onDelete} />
    );
});