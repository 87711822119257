import React, { createContext, SetStateAction, useState } from "react"

interface IAuthContext {
    auth: boolean;
    setAuth: React.Dispatch<SetStateAction<boolean>>;
}

const AuthContext = createContext({} as IAuthContext);

interface Props {
    children?: React.ReactNode
}

export const AuthProvider = ({children} : Props) => {
    const [auth, setAuth] = useState(sessionStorage.getItem("isLoggedIn") === "1");

    return (
        <AuthContext.Provider value={{auth, setAuth}}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext